import { API, graphqlOperation, Auth } from 'aws-amplify'
import { userByUserName, ordersByOrganizationId, membersByOrganizationId } from '../graphql/queries'

// Bug tracking
import Bugsnag from '@bugsnag/js'

const getAllYearsOrders = async (yearmonth) => {
    try {
        const user_info = await Auth.currentUserInfo()
        const username = user_info.username
        const user = await API.graphql(graphqlOperation(userByUserName, { userName: username }))
        const orgId = user.data.userByUserName.items[0].organizationId
        var nextToken = null;
        var count = 0
        var filteredOrders
        var ORDERS = []
        while (nextToken || count === 0) {
            count = 1
            filteredOrders = await API.graphql(
            graphqlOperation(ordersByOrganizationId, {organizationId: orgId, limit: 900, nextToken:nextToken}))
            nextToken = filteredOrders.data.ordersByOrganizationId.nextToken
            ORDERS = ORDERS.concat(filteredOrders.data.ordersByOrganizationId.items)
        }
        ORDERS.sort(function(a, b) {
            var textA = a.createdAt;
            var textB = b.createdAt;
            return (textA < textB) ? 1 : (textA > textB) ? -1 : 0;
        });
        var ordersFromYearMonth = []
        for (var i = 0; i < ORDERS.length; i++) {
            if (ORDERS[i].createdAt.includes(yearmonth)) {
                // TODO: Short circuit this to stop searching after we're done scanning the given check date month
                ordersFromYearMonth.push(ORDERS[i])
            }
        }
        return ordersFromYearMonth
    } catch (error) {
        Bugsnag.notify(error);
        console.log(error)
    }
}

// const getMemberByPNumber = async(pNumber) => {
//     var MEMBER = []
//     try {
//         const user_info = await Auth.currentUserInfo()
//         const username = user_info.username
//         const user = await API.graphql(graphqlOperation(userByUserName, { userName: username }))
//         const orgId = user.data.userByUserName.items[0].organizationId
//         let filter = {
//             and: [{ pnumber: { eq: pNumber} },
//                  { organizationId: {eq: orgId} }]
//         };
//         var nextToken = null;
//         var count = 0
//         var filteredList
        
//         //TODO: change to get query rather than fliterd list query
//         while (nextToken || count === 0) {
//             count = 1
//             filteredList = await API.graphql(
//             graphqlOperation(listMembers, {
//             filter: filter,
//             limit: 900,
//             nextToken:nextToken}))
//             nextToken = filteredList.data.listMembers.nextToken
//             MEMBER = MEMBER.concat(filteredList.data.listMembers.items)
//         }
//     } catch (error) {
        // Bugsnag.notify(error);
//         console.log(error)
//     }
//     return MEMBER
// }

export const generateOrderFormData = async (yearmonth) => {
    // must pass year in format 'YYYY'
    const ORDERS = await getAllYearsOrders(yearmonth)
    var orderFormData = []
    if (!ORDERS) {
        return orderFormData
    }
    else {

        // Get Member array
        var MEMBER = []
        try {
            const user_info = await Auth.currentUserInfo()
            const username = user_info.username
            const user = await API.graphql(graphqlOperation(userByUserName, { userName: username }))
            const orgId = user.data.userByUserName.items[0].organizationId
            var nextToken = null;
            var count = 0
            var filteredList
            
            //TODO: change to get query rather than fliterd list query
            while (nextToken || count === 0) {
                count = 1
                filteredList = await API.graphql(
                graphqlOperation(membersByOrganizationId, {
                    organizationId: orgId,
                    limit: 900,
                    nextToken:nextToken
                }))
                nextToken = filteredList.data.membersByOrganizationId.nextToken
                MEMBER = MEMBER.concat(filteredList.data.membersByOrganizationId.items)
            }
        } catch (error) {
            Bugsnag.notify(error);
            console.log(error)
        }
        
        for (var i = 0; i < ORDERS.length; i++) {
            try {
                let donorPNumber = ORDERS[i].donorPNumber
                let receiverPNumber = ORDERS[i].receiverPNumber
                var donor = MEMBER.find(obj => {
                    return obj.pnumber === donorPNumber
                })
                var receiver = MEMBER.find(obj => {
                    return obj.pnumber === receiverPNumber
                })
                if (receiver && donor) {
                    orderFormData.push([ORDERS[i].createdAt, donor.pnumber, donor.fname + " " + donor.lname, receiver.ptype, receiver.pnumber, receiver.fname + " " + receiver.lname, receiver.DOB])
                }

            } catch (error) {
                Bugsnag.notify(error);
                console.log(error)
            }
        }
        orderFormData.sort(function(a, b) {
            var textA = a[0];
            var textB = b[0];
            return (textA > textB) ? 1 : (textA < textB) ? -1 : 0;
        });

        orderFormData.unshift(["Order Created Date", "Donor ID", "Donor Name", "Receiver Type", "Receiver ID", "Receiver Name", "Receiver DOB/Anniversary"])

        return orderFormData
    }
}