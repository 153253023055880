import { API, graphqlOperation, Auth } from 'aws-amplify'
import { userByUserName, ordersByOrganizationId, membersByOrganizationId } from '../graphql/queries'

//UI
import { toaster } from 'evergreen-ui'


// Bug tracking
import Bugsnag from '@bugsnag/js'

const getAllMonthsOrders = async (yearmonth) => {
    try {
        const user_info = await Auth.currentUserInfo()
        const username = user_info.username
        const user = await API.graphql(graphqlOperation(userByUserName, { userName: username }))
        const orgId = user.data.userByUserName.items[0].organizationId
        var nextToken = null;
        var count = 0
        var filteredOrders
        var ORDERS = []
        let oFilter = {
            and: [{ checkDate: { contains: yearmonth} }]
        };
        while (nextToken || count === 0) {
            count = 1
            filteredOrders = await API.graphql(
            graphqlOperation(ordersByOrganizationId, {
                organizationId: orgId,
              filter: oFilter,
              limit: 900,
              nextToken:nextToken}))
            nextToken = filteredOrders.data.ordersByOrganizationId.nextToken
            ORDERS = ORDERS.concat(filteredOrders.data.ordersByOrganizationId.items)
        }
        ORDERS.sort(function(a, b) {
            var textA = a.createdAt;
            var textB = b.createdAt;
            return (textA > textB) ? 1 : (textA < textB) ? -1 : 0;
        });
        return ORDERS
    } catch (error) {
        Bugsnag.notify(error);
        console.log(error)
    }
}

// TODO:implement backend solution to get orders
// const getAllMonthsOrders = async (yearmonth) => {
//     try {
//         const api = 'https://opfeziao6g.execute-api.us-east-1.amazonaws.com/devtwo/orders/' + yearmonth;
//         const data = { "monthyear" : yearmonth };
//         const response = await axios
//             .post(api, data)
//             .then((response) => {
//                 console.log(response);
//             })
//             .catch((error) => {
                // Bugsnag.notify(error);
//                 console.log(error);
//             });
//       console.log(response)
//     } catch (error) {
        // Bugsnag.notify(error);
//         console.log(error)
//     }
//     // Need return
// }

// const getMemberByPNumber = async(pNumber) => {
//     var MEMBER = []
//     try {
//         const user_info = await Auth.currentUserInfo()
//         const username = user_info.username
//         const user = await API.graphql(graphqlOperation(userByUserName, { userName: username }))
//         const orgId = user.data.userByUserName.items[0].organizationId
//         let filter = {
//             and: [{ pnumber: { eq: pNumber} },
//                  { organizationId: {eq: orgId} }]
//         };
//         var nextToken = null;
//         var count = 0
//         var filteredList
        
//         //TODO: change to get query rather than fliterd list query
//         while (nextToken || count === 0) {
//             count = 1
//             filteredList = await API.graphql(
//             graphqlOperation(listMembers, {
//             filter: filter,
//             limit: 900,
//             nextToken:nextToken}))
//             nextToken = filteredList.data.listMembers.nextToken
//             MEMBER = MEMBER.concat(filteredList.data.listMembers.items)
//         }
//     } catch (error) {
        // Bugsnag.notify(error);
//         console.log(error)
//     }
//     return MEMBER
// }

export const generateDepositFormData = async (monthyear) => {
    // must pass monthyear in format 'YYYY-MM'
    const ORDERS = await getAllMonthsOrders(monthyear)
    var depositFormData = []
    var processedChecks = []

    // Get members
    var MEMBER = []
    try {
        const user_info = await Auth.currentUserInfo()
        const username = user_info.username
        const user = await API.graphql(graphqlOperation(userByUserName, { userName: username }))
        const orgId = user.data.userByUserName.items[0].organizationId
        var nextToken = null;
        var count = 0
        var filteredList
        
        //TODO: change to get query rather than fliterd list query
        while (nextToken || count === 0) {
            count = 1
            filteredList = await API.graphql(
            graphqlOperation(membersByOrganizationId, {
                organizationId: orgId,
                limit: 900,
                nextToken:nextToken
            }))
            nextToken = filteredList.data.membersByOrganizationId.nextToken
            MEMBER = MEMBER.concat(filteredList.data.membersByOrganizationId.items)
        }
    } catch (error) {
        Bugsnag.notify(error);
        console.log(error)
    }
    
    if (!ORDERS) { 
        toaster.warning("No orders found for the date range selected. Please try another date range.", {duration: 5,})
        return false;
    }
    for (var i = 0; i < ORDERS.length; i++) {
        var check = {donorPNumber: ORDERS[i].donorPNumber, checkNumber: ORDERS[i].checkNumber, checkDate: ORDERS[i].checkDate, checkAmount: ORDERS[i].amount}

        // TODO: optimize this
        var duplicate = false
        for (var j = 0; j < processedChecks.length; j++) {
            if (JSON.stringify(processedChecks[j]) === JSON.stringify(check)) {
                duplicate = true
            }
        }
        // if (!processedChecks.includes(check))
        let paymentType = ORDERS[i].paymentType ? ORDERS[i].paymentType : 'Check'
        if (!duplicate && ORDERS[i].amount !== '0' && ORDERS[i].amount !== '')
        {
            processedChecks.push(check)

            let donor = ORDERS[i].donorPNumber
            var member = MEMBER.find(obj => {
                return obj.pnumber === donor
              })
            // var member = await getMemberByPNumber(ORDERS[i].donorPNumber)
            // var member = false
            if (!member) {
                member = [{
                    pNumber: ORDERS[i].donorPNumber,
                    fname: "Member not found",
                    lname: "Member not found",
                }]
            }
            depositFormData.push([ORDERS[i].donorPNumber, ORDERS[i].checkNumber ? ORDERS[i].checkNumber.toString() : "", ORDERS[i].checkDate ? ORDERS[i].checkDate : "", ORDERS[i].amount ? parseFloat(ORDERS[i].amount).toFixed(2) : "", paymentType, member.fname, member.lname, ""])
        }
    }
    // Old sorting function, not necessary as ordering payments by date made
    // depositFormData.sort(function(a, b) {
    //     var textA = a[5];
    //     var textB = b[5];
    //     return (textA > textB) ? 1 : (textA < textB) ? -1 : 0;
    // });

    depositFormData.unshift(["Consitituent I.D.", "Payment Number", "Payment Date", "Payment Amount", "Payment Type", "Payment First Name", "Payment Last Name", "Comments"])

    return depositFormData
}