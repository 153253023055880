import React, {useState} from 'react';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';


// UI
import { Button, toaster } from 'evergreen-ui'

// Bug tracking
import Bugsnag from '@bugsnag/js'

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  // const [authNumber, setAuthNumber] = useState(false);

  const handleSubmit = async (event) => {

    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setButtonLoading(true)

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    
    const {error} = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: 'https://www.wishwell.onegift.ai/makeawish',
      },
      redirect: 'if_required'
    })


    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      Bugsnag.notify(error);
      setErrorMessage(error.message);
      toaster.danger('Card declined. Please update your info and try again.')
      setButtonLoading(false)
    } else {
      toaster.success(<div><p>Payment successful. Thanks for your gift! Your gift will appear as 'Wishwell * {props.orgName}' on your credit card statement.</p><p>If you aren't part of the {props.orgName} WishWell community yet, you can register in 30 seconds via the 'Register' button at the top of this screen. And, if you enjoyed using WishWell, please leave us a testimonial to help spread the word: <a href="https://senja.io/p/onegift/r/Wg2DDC" target="_blank" rel="noopener noreferrer">Leave Testimonial</a></p></div>)

      // TODO: get authNumber and add to transaction
      // props.completeTransaction(authNumber)
      props.completeTransaction()
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <br></br>
      <br></br>
      <Button className="my-10 ml-44" appearance="primary" isLoading={buttonLoading} disabled={!stripe}>Gift {props.currency}{parseFloat(props.amount).toFixed(2)}</Button>
      {/* Show error message to your customers */}
    </form>
  )
};

export default CheckoutForm;