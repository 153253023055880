/* src/App.js */
import React, { useState } from 'react'
import { API, Storage } from 'aws-amplify'
import { v4 as uuid } from 'uuid'

// CSV Download
import { CSVLink } from "react-csv";

// Stripes libraries
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from '../Components/CheckoutForm'

// UI
import { Autocomplete, Position, DollarIcon, VideoIcon, FollowerIcon, Tooltip, EditIcon, AddIcon, HelpIcon, Popover, SettingsIcon, Menu, IconButton, AlignJustifyIcon, Spinner, TextInput, SelectField, Button, Pane, Dialog, Label, Checkbox, SearchTemplateIcon, TextInputField, toaster } from 'evergreen-ui'
import awsconfig from '../aws-exports'
import wishWellLogo from '../Components/Images/WishWell.png'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../Styles/button.css'
import '../Styles/navbar.css'
import '../Styles/progressbar.css'
import logo from '../wishwellbyonegift.svg'

// Bug tracking
import Bugsnag from '@bugsnag/js'

// Analytics
import mixpanel from 'mixpanel-browser';


// Get backend environment
const envStart = awsconfig.aws_cloud_logic_custom[0].endpoint.lastIndexOf('/');
const env = awsconfig.aws_cloud_logic_custom[0].endpoint.slice(envStart+1);

env === 'dev' ? 
  mixpanel.init('4ae47a1aaceb8492e67a539b65e47a96', {debug: true}) :
  mixpanel.init('3f21124f040a7330a329c01149dd5fba', {debug: false})

export class MakeAWish extends React.Component {

    constructor() {
        super()
        const today = new Date();
        const currentMonth = today.getMonth() + 1;
        const currentQuarter = Math.ceil(currentMonth / 3);
        const currentYear = today.getFullYear().toString();
        this.state = {
            donorPNumber: "",
            // donationsMultiplier: 1,
            // donationsMultiplierOptions: [
            //     {label: 'single', value: 1},
            //     {label: 'double', value: 2},
            //     {label: 'triple', value: 3},
            // ],
            donor: {},
            donorSelected: "",
            check: {
                checkNumber: "",
                checkDate: "",
                checkAmount: ""
            },
            credit: {
                cardAmount: "",
                cardFees: false,
                cardRecurringDonation: false,
                cardReceiptEmail: "",
                cardReceipt: false,
            },
            chargeAmount: 0,
            quarterMonths: [],
            selectedQuarter: "Q" + ((currentQuarter % 4) + 1).toString(),
            selectedYear: currentQuarter === 4 ? (parseInt(currentYear) + 1).toString() : currentYear,
            currentYear: currentYear,
            currentQuarter: "Q" + currentQuarter,
            allMembers: [],
            allPlusAnniversary: [],
            allOrgs: [],
            eventBasedMemberList: [],
            existingOrders: [],
            donationCount: 0,
            donationsByMonthcount: [0,0,0],
            showPlaceOrdersModal: false,
            showCheckoutModal: false,
            stripeOptions: {},
            saveOrdersLoading: false,
            stripePromise: '',
            paymentType: "Credit Card",
            results_1: [],
            results_2: [],
            results_3: [],
            donorName: '',
            isShowOrderFormLoading: false,
            isLoading: true,
            cardReceipt: [],
            ordersLoading: false,
            orgSettings: {
                organizationId: "",
                minWishPrice: 1,
                brandColor: "#0A003A",
                logo: [
                    "WishWell by OneGift",
                    wishWellLogo
                ]
            },
            makeWishes: false,
            member: {},
            updateMemberEmailConfirmationModalShown: false,
            updateMemberEmailConfirmationModalLoading: false,
            registerModalShown: false,
            registrationLoading: false,
            updateMemberModalShown: false,
            updateMemberLoading: false,
            selectAll: false,
          }

        this.loadPage = this.loadPage.bind(this)
        this.checkURL = this.checkURL.bind(this)
        this.handleKeyPressed = this.handleKeyPressed.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.registerMember = this.registerMember.bind(this)
        this.updateMember = this.updateMember.bind(this)
        this.updateMemberEmailSend = this.updateMemberEmailSend.bind(this)
        this.showOrderForm = this.showOrderForm.bind(this)
        this.completeTransaction = this.completeTransaction.bind(this)
        this.closeCheckoutEarly = this.closeCheckoutEarly.bind(this)
        this.checkout = this.checkout.bind(this)
        this.checkoutForDonation = this.checkoutForDonation.bind(this)
        this.makeWishes = this.makeWishes.bind(this)
        
    }

    // New functions

    componentDidMount = async () => {
        await this.loadPage()
    }

    loadPage = async () => {
        
        // Tracking
        mixpanel.track('Makeawish page visited');
        const searchParams = new URLSearchParams(window.location.search);
        const reciever = searchParams.get('reciever');
        const toDonate = searchParams.get('donate');
        const id = await this.checkURL((reciever || toDonate) ? true : false)

        if (toDonate) {
            this.setState({donateNowModalShown: true})
        }
        
        // Retrieve members
        var allMembers
        try {
            var apiName = 'publicorderform';
            var path = '/publicorderform/getallactivemembers';
            var myInit = { // OPTIONAL
                body: {
                    orgId: this.state.orgSettings.organizationId
                },
                headers: {}, // OPTIONAL
            };

            allMembers = await API.post(apiName, path, myInit);
        } catch (error) {
            Bugsnag.notify(error);
            console.log(error)
        }

        // Filter out all members with ptype of 5 (anniversary record)
        var filteredMembers = []
        if (allMembers) { filteredMembers = allMembers.body.filter(member => !member.ptype || (member.ptype && member.ptype !== 5)) }

        // One-off donation
        var donorName = this.state.donorName
        var donorSelected = this.state.donorSelected
        var oneOff = false
        if (reciever) {
            if (reciever === 'done') {
                toaster.success(<div>You've just made a donation with WishWell! WishWell takes 15 minutes to setup, is fully automated, and can increase annual recurring donations for organizations by 10% or more. If you know any organizations that may benefit, please tell them to checkout <a href="https://wishwell.ai" target="_blank" rel="noopener noreferrer">wishwell.ai</a>!</div>, {duration: 15})
            }
            else {
                // get member name from pnumber
                this.setState({recieverPNumber: reciever})
                const recieverIndex = filteredMembers.findIndex(obj => obj.pnumber === parseInt(reciever))
                if (recieverIndex !== -1) {
                    donorSelected = `${filteredMembers[recieverIndex].fname} ${filteredMembers[recieverIndex].lname} (${filteredMembers[recieverIndex].pnumber})`
                    donorName = `${filteredMembers[recieverIndex].fname} ${filteredMembers[recieverIndex].lname}`
                    oneOff = true
                }
            }
        }
        this.setState({
            allMembers: filteredMembers,
            allPlusAnniversary: allMembers.body,
            isLoading: false,
            stripePromise: env === 'dev' ? loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY_TEST) : loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY_LIVE),
            showOneOffDonationModal: reciever && reciever !== 'done' ? true : false,
            oneOffDonationReciever: reciever ? reciever : '',
            donorName: donorName,
            donorSelected: donorSelected,
            oneOff: oneOff,
            toDonate: toDonate
        });

        // Get member if this is an update link
        if (id) {
            const userIndex = this.state.allPlusAnniversary.findIndex(obj => obj.id === id)
            if (userIndex !== -1) {
                const member = this.state.allPlusAnniversary[userIndex]
                const date = new Date(this.state.allPlusAnniversary[userIndex].DOB);
                if (!member.eforms) { member.eforms = false }
                if (!member.birthdayBooster) { member.birthdayBooster = false }
                if (!member.eReminders) { member.eReminders = false }
                this.setState({
                    member: member,
                    updateMemberModalShown: true,
                    selectedDate: date, 
                    formattedDOB: date
                })
            }
        }
    }

    checkURL = async (oneOff) => {
        const searchParams = new URLSearchParams(window.location.search);
        const makeAWishURL = searchParams.get('org');
        const id = searchParams.get('id');
        const wishesCompleted = searchParams.get('wishes');
        const action = searchParams.get('action');
        const registered = searchParams.get('registered');

        if (action === 'register' && registered !== 'true') {
            this.setState({registerModalShown: true})
        }

        // Get settings
        try {
            var apiName = 'publicorderform';
            var path = '/publicorderform/getsettings';
            var myInit = { // OPTIONAL
                body: {
                    makeAWishURL: makeAWishURL
                },
                headers: {}, // OPTIONAL
            };

            var settings = await API.post(apiName, path, myInit);
            var orgSettings = {
                organizationId: "",
                chapterName: "WishWell",
                minWishPrice: 1,
                brandColor: "#0A003A",
                logo: [
                    "WishWell by OneGift",
                    wishWellLogo
                ],
                orgType: "{value=Nonprofit, label=Nonprofit}"
            }

            var currencySymbol = "$"
            var countryCurrency = "USD"
            if (settings.body.length > 0) {
                orgSettings = settings.body[0]
                // Get currency symbol
                const stripeCountryInfo = [
                    {
                    country: 'Australia',
                    code: 'AU',
                    currency: 'AUD',
                    symbol: '$'
                    },
                    {
                    country: 'Austria',
                    code: 'AT',
                    currency: 'EUR',
                    symbol: '€'
                    },
                    {
                    country: 'Belgium',
                    code: 'BE',
                    currency: 'EUR',
                    symbol: '€'
                    },
                    {
                    country: 'Bulgaria',
                    code: 'BG',
                    currency: 'BGN',
                    symbol: 'лв'
                    },
                    {
                    country: 'Canada',
                    code: 'CA',
                    currency: 'CAD',
                    symbol: '$'
                    },
                    {
                    country: 'Croatia',
                    code: 'HR',
                    currency: 'HRK',
                    symbol: 'kn'
                    },
                    {
                    country: 'Cyprus',
                    code: 'CY',
                    currency: 'EUR',
                    symbol: '€'
                    },
                    {
                    country: 'Czech Republic',
                    code: 'CZ',
                    currency: 'CZK',
                    symbol: 'Kč'
                    },
                    {
                    country: 'Denmark',
                    code: 'DK',
                    currency: 'DKK',
                    symbol: 'kr'
                    },
                    {
                    country: 'Estonia',
                    code: 'EE',
                    currency: 'EUR',
                    symbol: '€'
                    },
                    {
                    country: 'Finland',
                    code: 'FI',
                    currency: 'EUR',
                    symbol: '€'
                    },
                    {
                    country: 'France',
                    code: 'FR',
                    currency: 'EUR',
                    symbol: '€'
                    },
                    {
                    country: 'Germany',
                    code: 'DE',
                    currency: 'EUR',
                    symbol: '€'
                    },
                    {
                    country: 'Gibraltar',
                    code: 'GI',
                    currency: 'GBP',
                    symbol: '£'
                    },
                    {
                    country: 'Greece',
                    code: 'GR',
                    currency: 'EUR',
                    symbol: '€'
                    },
                    {
                    country: 'Hong Kong',
                    code: 'HK',
                    currency: 'HKD',
                    symbol: 'HK$'
                    },
                    {
                    country: 'Hungary',
                    code: 'HU',
                    currency: 'HUF',
                    symbol: 'Ft'
                    },
                    {
                    country: 'Ireland',
                    code: 'IE',
                    currency: 'EUR',
                    symbol: '€'
                    },
                    {
                    country: 'Italy',
                    code: 'IT',
                    currency: 'EUR',
                    symbol: '€'
                    },
                    {
                    country: 'Japan',
                    code: 'JP',
                    currency: 'JPY',
                    symbol: '¥'
                    },
                    {
                    country: 'Latvia',
                    code: 'LV',
                    currency: 'EUR',
                    symbol: '€'
                    },
                    {
                    country: 'Liechtenstein',
                    code: 'LI',
                    currency: 'CHF',
                    symbol: 'CHF'
                    },
                    {
                    country: 'Lithuania',
                    code: 'LT',
                    currency: 'EUR',
                    symbol: '€'
                    },
                    {
                    country: 'Luxembourg',
                    code: 'LU',
                    currency: 'EUR',
                    symbol: '€'
                    },
                    {
                    country: 'Malta',
                    code: 'MT',
                    currency: 'EUR',
                    symbol: '€'
                    },
                    {
                    country: 'Mexico',
                    code: 'MX',
                    currency: 'MXN',
                    symbol: '$'
                    },
                    {
                    country: 'Netherlands',
                    code: 'NL',
                    currency: 'EUR',
                    symbol: '€'
                    },
                    {
                    country: 'New Zealand',
                    code: 'NZ',
                    currency: 'NZD',
                    symbol: '$'
                    },
                    {
                    country: 'Norway',
                    code: 'NO',
                    currency: 'NOK',
                    symbol: 'kr'
                    },
                    {
                    country: 'Poland',
                    code: 'PL',
                    currency: 'PLN',
                    symbol: 'zł'
                    },
                    {
                    country: 'Portugal',
                    code: 'PT',
                    currency: 'EUR',
                    symbol: '€'
                    },
                    {
                    country: 'Romania',
                    code: 'RO',
                    currency: 'RON',
                    symbol: 'lei'
                    },
                    {
                    country: 'Singapore',
                    code: 'SG',
                    currency: 'SGD',
                    symbol: '$'
                    },
                    {
                    country: 'Slovakia',
                    code: 'SK',
                    currency: 'EUR',
                    symbol: '€'
                    },
                    {
                    country: 'Slovenia',
                    code: 'SI',
                    currency: 'EUR',
                    symbol: '€'
                    },
                    {
                    country: 'Spain',
                    code: 'ES',
                    currency: 'EUR',
                    symbol: '€'
                    },
                    {
                    country: 'Sweden',
                    code: 'SE',
                    currency: 'SEK',
                    symbol: 'kr'
                    },
                    {
                    country: 'Switzerland',
                    code: 'CH',
                    currency: 'CHF',
                    symbol: 'CHF'
                    },
                    {
                    country: 'Thailand',
                    code: 'TH',
                    currency: 'THB',
                    symbol: '฿'
                    },
                    {
                    country: 'United Arab Emirates',
                    code: 'AE',
                    currency: 'AED',
                    symbol: 'د.إ'
                    },
                    {
                    country: 'United Kingdom',
                    code: 'GB',
                    currency: 'GBP',
                    symbol: '£'
                    },
                    {
                    country: 'United States',
                    code: 'US',
                    currency: 'USD',
                    symbol: '$'
                    }
                ];

                const countryMap = orgSettings.country
                const countryObject = JSON.parse(`{${countryMap.replace("{", "").replace("}", "").replace(/(\w+)\s*=\s*([^,}]+)/g, '"$1":"$2"')}}`)
                const countryInfo = stripeCountryInfo.find(info => info.country === countryObject.value);
                countryCurrency = countryInfo ? countryInfo.currency : 'usd';

                currencySymbol = new Intl.NumberFormat('en-US', { style: 'currency', currency: countryCurrency }).formatToParts()[0].value;
                if (orgSettings.logo && orgSettings.logo[0] !== "") {
                    const logo = await Storage.get(orgSettings.logo[0])
                    orgSettings.logo[1] = logo
                }
            }

            if (wishesCompleted === "done" && oneOff) {
                toaster.success(<div><p>Thanks for making a donation with WishWell! If you haven't already, you can register for future {orgSettings.chapterName} fundraisers in 30 seconds via the Register button at the top of the page.</p><p>WishWell takes 15 minutes to setup, is fully automated, and can increase annual recurring donations for organizations by 10% or more. If you know any other organizations that may benefit, please tell them to checkout <a href="https://wishwell.ai" target="_blank" rel="noopener noreferrer">wishwell.ai</a>. We donate {this.state.currencySymbol}100 to {this.state.orgSettings.chapterName} for every referral!</p></div>, {duration: 15})
            }
            if (wishesCompleted === "done" && !oneOff) {
                toaster.success(<div><p>Thanks for making a donation with WishWell!</p><p>WishWell takes 15 minutes to setup, is fully automated, and can increase annual recurring donations for organizations by 10% or more. If you know any other organizations that may benefit, please tell them to checkout <a href="https://wishwell.ai" target="_blank" rel="noopener noreferrer">wishwell.ai</a>. We donate {this.state.currencySymbol}100 to {this.state.orgSettings.chapterName} for every referral!</p></div>, {duration: 15})
            }

            // Get org
            apiName = 'publicorderform';
            path = '/publicorderform/getorgbyid';
            myInit = { // OPTIONAL
                body: {
                    organizationId: orgSettings.organizationId
                },
                headers: {}, // OPTIONAL
            };

            const orgNameResponse = await API.post(apiName, path, myInit);
            const org = orgNameResponse.body

            if (!oneOff) {
                if (org[0] && org[0].parentOrgId !== "859d0bb4-21c4-4c0d-9804-126093d78552" && !orgSettings.stripeOnboardingComplete) {
                //  toaster.warning("WishWell is not enabled yet. Please log in or contact an administrator and finish your setup before attempting to make any donations.", {duration: 10})
                }
                if (((org[0] && org[0].parentOrgId === "859d0bb4-21c4-4c0d-9804-126093d78552") || orgSettings.stripeOnboardingComplete) && wishesCompleted !== "done" && action !== 'register' && !id) {
                    toaster.notify("Hi! please select the quarter, year, enter in your name or ID number and press 'Select Wishes' If you're not finding your name, you probably need to register. Simply press the 'Register' button on the top right of the page to register in 30 seconds or less or contact your community admin!", {duration: 10})
                }
            }

            const orgType = JSON.parse(`{${orgSettings.orgType.replace("{", "").replace("}", "").replace(/(\w+)\s*=\s*([^,}]+)/g, '"$1":"$2"')}}`)
            var isNonProfit = true
            if (orgType.value === 'Place of Work' ||
            orgType.value === 'Community' ||
            orgType.value === 'School' ||
            orgType.value === 'Other') {
                isNonProfit = false
            }

            this.setState({
                orgSettings: orgSettings,
                organization: org[0],
                nonProfit: isNonProfit,
                currencySymbol: currencySymbol,
                countryCurrency: countryCurrency
            })
        } catch (error) {
            console.log(error)
            Bugsnag.notify(error)
        }
        return id
    }

    handleKeyPressed(e) {
        if (e.key === 'Enter') {
            this.showOrderForm();
          }
    }

    handleChange(event, target) {
        if (target && target.includes('member.')) {
            const updatedMember = this.state.member
            if (target === 'member.DOB') {
                const date = new Date(event)
                this.setState({ selectedDate: date, formattedDOB: date });
                const localDate = date.toLocaleDateString().split('/')
                const formattedDate = `${localDate[2]}-${localDate[0].padStart(2, '0')}-${localDate[1].padStart(2, '0')}`; // Join the parts in the desired order
                updatedMember.DOB = formattedDate
            }
            else {
                updatedMember[event.target.name] = event.target.type === 'checkbox' ? event.target.checked : event.target.value
            }
            this.setState({ member: updatedMember });
        }
        else if (event.target && event.target.id === 'quarter') {
            this.setState({ selectedQuarter: event.target.value });
            return
        }
        else if (event.target && event.target.id === 'year') {
            this.setState({ selectedYear: event.target.value });
            return
        }
        else if (event.target) {
            const target = event.target
            const field = target.name
            const value = target.value
            if (field === 'paymenttype') {
                if (target[0].selected) {
                    this.setState({ paymentType: "Check" })
                }
                else {
                    this.setState({ paymentType: "Credit Card" })
                }
            }
            else if (field === 'donorPNumber') {
                this.setState({donorPNumber: value})
            }
            else if (field.includes('check')) {
                const updatedCheck = this.state.check
                updatedCheck[field] = value
                this.setState({
                    check: updatedCheck
                })
            }
            else if (field.includes('card')) {
                const updatedCredit = this.state.credit
                if (target.type === 'checkbox') {
                    updatedCredit[field] === false ? updatedCredit[field] = true : updatedCredit[field] = false
                }
                else {
                    updatedCredit[field] = value
                }
                this.setState({
                    credit: updatedCredit
                })
            }
            else if (field.includes('selectAll')) {
                var eventBasedMemberList = this.state.eventBasedMemberList
                var newDonationsByMonthCount = this.state.donationsByMonthcount
                if (!this.state.selectAll) {
                    for (var i = 0; i < eventBasedMemberList.length; i++) {
                        eventBasedMemberList[i].ordered = true
                        let index = parseInt(this.state.eventBasedMemberList[i].DOB.split('-')[1] - 1)%3
                        newDonationsByMonthCount[index] = newDonationsByMonthCount[index] + 1
                    }
                }
                else {
                    for (i = 0; i < this.state.eventBasedMemberList.length; i++) {
                        eventBasedMemberList[i].ordered = false
                        let index = parseInt(this.state.eventBasedMemberList[i].DOB.split('-')[1] - 1)%3
                        newDonationsByMonthCount[index] = newDonationsByMonthCount[index] - 1
                    }
                }

                const ordered = this.state.selectAll ? false : true
                const updatedResults1 = this.state.results_1.map(item => {
                    return {
                        ...item,
                        props: { 
                            ...item.props,
                            checked: ordered
                        }
                    };
                });
                const updatedResults2 = this.state.results_2.map(item => {
                    return {
                        ...item,
                        props: { 
                            ...item.props,
                            checked: ordered
                        }
                    };
                });
                const updatedResults3 = this.state.results_3.map(item => {
                    return {
                        ...item,
                        props: { 
                            ...item.props,
                            checked: ordered
                        }
                    };
                });

                this.state.results_1 = []
                this.state.results_2 = []
                this.state.results_3 = []

                this.setState({
                    eventBasedMemberList: eventBasedMemberList,
                    donationCount: this.state.selectAll ? 0 : this.state.eventBasedMemberList.length,
                    donationsByMonthcount: newDonationsByMonthCount,
                    selectAll: this.state.selectAll ? false : true,
                    results_1: updatedResults1,
                    results_2: updatedResults2,
                    results_3: updatedResults3
                })
                this.forceUpdate()
            }
        }
        else {
            if (this.state.eventBasedMemberList[event].ordered) {
                this.state.eventBasedMemberList[event].ordered = false
                let newDonationsByMonthCount = this.state.donationsByMonthcount
                let index = parseInt(this.state.eventBasedMemberList[event].DOB.split('-')[1] - 1)%3
                newDonationsByMonthCount[index] = newDonationsByMonthCount[index] - 1

                // Check/uncheck box
                if (index === 0) {
                    var updatedResults = this.state.results_1.map((item, i) => {
                        if (item.props.id === event) {
                            return {
                            ...item,
                            props: {
                                ...item.props,
                                checked: false
                            }
                            };
                        }
                        return item;
                    });
                    this.setState({ results_1: updatedResults })
                }
                else if (index === 1) {
                    updatedResults = this.state.results_2.map((item, i) => {
                        if (item.props.id === event) {
                            return {
                            ...item,
                            props: {
                                ...item.props,
                                checked: false
                            }
                            };
                        }
                        return item;
                    });
                    this.setState({ results_2: updatedResults })
                }
                else if (index === 2) {
                    updatedResults = this.state.results_3.map((item, i) => {
                        if (item.props.id === event) {
                            return {
                            ...item,
                            props: {
                                ...item.props,
                                checked: false
                            }
                            };
                        }
                        return item;
                    });
                    this.setState({ results_3: updatedResults })
                }
                
                this.setState({
                    donationCount: this.state.donationCount - 1,
                    donationsByMonthcount: newDonationsByMonthCount
                })
            }
            else {
                this.state.eventBasedMemberList[event].ordered = true
                let newDonationsByMonthCount = this.state.donationsByMonthcount
                let index = parseInt(this.state.eventBasedMemberList[event].DOB.split('-')[1] - 1)%3
                newDonationsByMonthCount[index] = newDonationsByMonthCount[index] + 1

                // Check/uncheck box
                if (index === 0) {
                    var updatedResults = this.state.results_1.map((item, i) => {
                        if (item.props.id === event) {
                            return {
                            ...item,
                            props: {
                                ...item.props,
                                checked: true
                            }
                            };
                        }
                        return item;
                    });
                    this.setState({ results_1: updatedResults })
                }
                else if (index === 1) {
                    updatedResults = this.state.results_2.map((item, i) => {
                        if (item.props.id === event) {
                            return {
                            ...item,
                            props: {
                                ...item.props,
                                checked: true
                            }
                            };
                        }
                        return item;
                    });
                    this.setState({ results_2: updatedResults })
                }
                else if (index === 2) {
                    updatedResults = this.state.results_3.map((item, i) => {
                        if (item.props.id === event) {
                            return {
                            ...item,
                            props: {
                                ...item.props,
                                checked: true
                            }
                            };
                        }
                        return item;
                    });
                    this.setState({ results_3: updatedResults })
                }
                
                this.setState({
                    donationCount: this.state.donationCount - 1,
                    donationsByMonthcount: newDonationsByMonthCount
                })

                this.setState({
                    donationCount: this.state.donationCount + 1,
                    donationsByMonthcount: newDonationsByMonthCount
                })
            }
        }
    }

    registerMember = async () => {
        if ((!this.state.member.email && !this.state.member.phone) || !this.state.member.fname || !this.state.member.lname) {
            toaster.warning("Please fill out all fields. Either a phone number or email is required to get your cards!", {duration: 5})
            this.setState({ registrationLoading: false })
            return
        }
        if (!this.state.legalStuff) {
            toaster.warning("Please check the box to confirm that you have read and agree to the terms and conditions.", {duration: 5})
            this.setState({ registrationLoading: false })
            return
        }
        if (this.state.allPlusAnniversary.some(member => member.email.toLowerCase() === this.state.member.email.toLowerCase())) {
            if (!window.confirm("Your email is already associated with a WishWell donor account. If you don't want to create another account, you should search for your name in the Donor Name box. Are you sure you want to create another account?")) {
                this.setState({ registrationLoading: false })
                return
            }
        }
        try {
            const highestPnumber = this.state.allPlusAnniversary.reduce((acc, member) => {
                return Math.max(acc, parseInt(member.pnumber));
            }, 0);
            const newPnumber = highestPnumber + 1
            const apiName = 'publicorderform';
            var path = '/publicorderform/createmember';
            var myInit = { // OPTIONAL
                body: {
                    id: uuid(),
                    member: this.state.member,
                    pnumber: newPnumber,
                    organizationId: this.state.orgSettings.organizationId
                },
                headers: {}, // OPTIONAL
            };
            const create_member_response = await API.put(apiName, path, myInit);
            mixpanel.track('Donor Registered!');
            console.log(create_member_response)
            toaster.success("Registration successful! You can now make wishes!")
            this.setState({ registrationLoading: false, registerModalShown: false })
            // Add 'registered=true' to the URL
            const url = new URL(window.location);
            url.searchParams.set('registered', 'true');
            window.history.pushState({}, '', url);
            this.loadPage()
        } catch (error) {
            Bugsnag.notify(error);
            console.log(error)
        }
    }

    updateMember = async () => {
        if (!this.state.member.email || !this.state.member.fname || !this.state.member.lname || !this.state.member.DOB) {
            toaster.warning("Please fill out all fields.", {duration: 5})
            this.setState({ registrationLoading: false })
            return
        }
        try {
            const apiName = 'publicorderform';
            var path = '/publicorderform/updatemember';
            var myInit = { // OPTIONAL
                body: {
                    member: this.state.member,
                },
                headers: {}, // OPTIONAL
            };
            const update_member_response = await API.put(apiName, path, myInit);
            console.log(update_member_response)
            mixpanel.track('Donor info updated!');
            toaster.success("Member info updated!")
            this.setState({ updateMemberLoading: false, updateMemberModalShown: false })
        } catch (error) {
            Bugsnag.notify(error);
            console.log(error)
        }
    }

    updateMemberEmailSend = async () => {
        const email = document.getElementById("updateMemberEmail").value.toLowerCase()
        const userIndex = this.state.allPlusAnniversary.findIndex(obj => obj.email.toLowerCase() === email)
        if (userIndex !== -1) {
            const apiName = 'publicorderform';
            const path = '/publicorderform/sendupdateemail';
            var myInit = { // OPTIONAL
                body: {
                    member: this.state.allPlusAnniversary[userIndex],
                    orgSettings: this.state.orgSettings
                },
                headers: {}, // OPTIONAL
            };
            const emailResponse = await API.post(apiName, path, myInit);
            mixpanel.track('Donor info update requested!');
            toaster.success("Email confirmed. Please check your email for a link to update your account information.")
            this.setState({
                updateMemberEmailConfirmationModalShown: false,
                updateMemberEmailConfirmationModalLoading: false
            })
        }
        else {
            toaster.warning("Email not found. You can either try another email or register using the 'Register' button on the previous page.", {duration: 5})
            this.setState({
                updateMemberEmailConfirmationModalLoading: false
            })
        }
    }
    
    // Function: show order form to enter in orders
    showOrderForm = async () => {
        if (this.state.donorSelected === '') {
            toaster.warning("Please select your name from the dropdown Donor Name list. If you can't find it, you may need to register your info or contact support.", {duration: 5})
            this.setState({isShowOrderFormLoading: false})
            return
        }
        Bugsnag.addMetadata('Variables', {
            'Donor Selected': this.state.donorSelected
          })
        // Get pnumber from autocomplete component - it will be the number surrounded by the last set of parentheses
        const lastIndex = this.state.donorSelected.lastIndexOf('(');
        const donorPNumber = parseInt(this.state.donorSelected.substring(lastIndex + 1).split(')')[0]);
        let quarter = document.getElementById("quarter").value
        let year = document.getElementById("year").value
        this.setState({
            isShowOrderFormLoading: true,
            check: {
                checkNumber: "",
                checkDate: "",
                checkAmount: ""
            },
            credit: {
                cardAmount: "",
                cardFees: false,
                cardRecurringDonation: false,
                cardReceiptEmail: "",
                cardReceipt: false,
            },
            eventBasedMemberList: [],
            existingOrders: [],
            donationCount: 0,
            donationsByMonthcount: [0,0,0],
            showPlaceOrdersModal: false,
            saveOrdersLoading: false,
            paymentType: "Credit Card",
            results_1: [],
            results_2: [],
            results_3: [],
            donorName: "",
            ordersLoading: true,
        })

        var MEMBERS
        var member_for_org
        var memberOrg
        try {
            // Get first and last name
            const lastIndex = this.state.donorSelected.lastIndexOf('(');
            const nameWithoutNumber = this.state.donorSelected.slice(0, lastIndex - 1)
            const names = nameWithoutNumber.split(' ')
            const fname = names.slice(0, -1).join(' ');
            const lname = names[names.length - 1]
            member_for_org = this.state.allPlusAnniversary.filter(function (el) {
                return el.pnumber === donorPNumber &&
                    el.fname === fname &&
                    el.lname === lname
            });
            memberOrg = member_for_org[0].organizationId
            mixpanel.identify(member_for_org[0].email)
            mixpanel.people.set({
                'Donor Status': 'Registered',
            })
            mixpanel.people.set_once({
                'Donor Sign-Up Date': new Date().toISOString(),
            })
            mixpanel.track('Show order form button pressed');
            MEMBERS = this.state.allPlusAnniversary.filter(function (el) {
                return el.organizationId === memberOrg
            });
            // sort members
            MEMBERS.sort(function(a, b) {
                var textA = a.lname.toUpperCase();
                var textB = b.lname.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
        } catch (error) {
            Bugsnag.notify(error)
            Bugsnag.clearMetadata('Variables')
            console.log(error)
        }
        
        const memberCount = MEMBERS.length

        // Set months of quarter
        switch(quarter) {
          case "Q1":
            this.state.quarterMonths = ["January", "February", "March"]
            break;
          case "Q2":
            this.state.quarterMonths = ["April", "May", "June"]
            break;
          case "Q3":
            this.state.quarterMonths = ["July", "August", "September"]
            break;
          case "Q4":
            this.state.quarterMonths = ["October", "November", "December"]
            break;
          default: 
            break;
        }
        
        // Filter birthdays and anniversaries by quarter
        var quarterBirthdays = []
        var quarterAnniversaries = []
        for (var i = 0; i < memberCount; i++) {
            // Set donor name
            if (MEMBERS[i].pnumber === donorPNumber) { 
                this.setState({ 
                    donorName: MEMBERS[i].fname + ' ' + MEMBERS[i].lname,
                    donor: MEMBERS[i]
                })
            }
          switch(quarter) {
            case "Q1":
              if (MEMBERS[i].DOB && MEMBERS[i].DOB.split("-")[1] >= 1 &&
                  MEMBERS[i].DOB.split("-")[1] <= 3) {
                    if (MEMBERS[i].ptype !== 5) {
                      quarterBirthdays.push(MEMBERS[i])
                    }
                    else {
                      quarterAnniversaries.push(MEMBERS[i])
                    }
                  }
              continue;
            case "Q2":
              if (MEMBERS[i].DOB && MEMBERS[i].DOB.split("-")[1] >= 4 &&
                  MEMBERS[i].DOB.split("-")[1] <= 6) {
                    if (MEMBERS[i].ptype !== 5) {
                      quarterBirthdays.push(MEMBERS[i])
                    }
                    else {
                      quarterAnniversaries.push(MEMBERS[i])
                    }
                  }
                continue;
            case "Q3":
              if (MEMBERS[i].DOB && MEMBERS[i].DOB.split("-")[1] >= 7 &&
                  MEMBERS[i].DOB.split("-")[1] <= 9) {
                    if (MEMBERS[i].ptype !== 5) {
                      quarterBirthdays.push(MEMBERS[i])
                    }
                    else {
                      quarterAnniversaries.push(MEMBERS[i])
                    }
                  }
                  continue;
            case "Q4":
              if (MEMBERS[i].DOB && MEMBERS[i].DOB.split("-")[1] >= 10 &&
                  MEMBERS[i].DOB.split("-")[1] <= 12) {
                    if (MEMBERS[i].ptype !== 5) {
                      quarterBirthdays.push(MEMBERS[i])
                    }
                    else {
                      quarterAnniversaries.push(MEMBERS[i])
                    }
                  }
                  continue;
            default:
              // code block
          }
        }

        let ordersThisYear = false
        let ordersYear = year
        while(!ordersThisYear && (year - ordersYear < 2)) {
            this.state.results_1 = []
            this.state.results_2 = []
            this.state.results_3 = []

            try {
                // Retrieve active members
                const apiName = 'publicorderform';
                var path = '/publicorderform/getordersfororgdonoryear';
                var myInit = { // OPTIONAL
                    body: {
                        organizationId: memberOrg,
                        donor: donorPNumber,
                        year: parseInt(year)
                    },
                    headers: {}, // OPTIONAL
                };
    
                const orders = await API.post(apiName, path, myInit);
                var ORDERS = orders.body
            } catch (error) {
                Bugsnag.notify(error);
                console.log(error)
            }

            // Generate table with columns by month, checked if order exists already
            this.state.eventBasedMemberList = [...quarterBirthdays, ...quarterAnniversaries]
            // iterate through all members for the quarter
            for (i = 0; i < this.state.eventBasedMemberList.length; i++) {
                this.state.eventBasedMemberList[i].ordered = false
                
                // Set event type
                if (i < quarterBirthdays.length) {
                    this.state.eventBasedMemberList[i].event = 'Birthday'
                }
                else {
                    this.state.eventBasedMemberList[i].event = 'Anniversary'
                }

                // Check existing orders
                for(var j = 0; j < ORDERS.length; j++) {
                    if (ORDERS[j].receiverPNumber === this.state.eventBasedMemberList[i].pnumber) {
                        this.state.eventBasedMemberList[i].ordered = true
                        this.state.existingOrders.push(ORDERS[j])
                        this.state.donationCount++
                        this.state.donationsByMonthcount[0]++
                        ordersThisYear = true
                        break;
                    }
                }
                if (parseInt(this.state.eventBasedMemberList[i].DOB.split("-")[1]) % 3 === 1) {
                    this.state.results_1.push(
                        <OrderItem
                            pnumber={this.state.eventBasedMemberList[i].pnumber}
                            name={this.state.eventBasedMemberList[i].fname + ' ' + this.state.eventBasedMemberList[i].lname}
                            birthday={this.state.eventBasedMemberList[i].DOB.split("-")[2]}
                            checked={this.state.eventBasedMemberList[i].ordered}
                            type={this.state.eventBasedMemberList[i].event}
                            handleChange={this.handleChange.bind(this)}
                            id={i}
                        />
                    )
                }
                if (parseInt(this.state.eventBasedMemberList[i].DOB.split("-")[1]) % 3 === 2) {
                    this.state.results_2.push(
                        <OrderItem
                            pnumber={this.state.eventBasedMemberList[i].pnumber}
                            name={this.state.eventBasedMemberList[i].fname + ' ' + this.state.eventBasedMemberList[i].lname}
                            birthday={this.state.eventBasedMemberList[i].DOB.split("-")[2]}
                            checked={this.state.eventBasedMemberList[i].ordered}
                            type={this.state.eventBasedMemberList[i].event}
                            handleChange={this.handleChange.bind(this)}
                            id={i}
                        />
                    )
                }
                if (parseInt(this.state.eventBasedMemberList[i].DOB.split("-")[1]) % 3 === 0) {
                    this.state.results_3.push(
                        <OrderItem
                            pnumber={this.state.eventBasedMemberList[i].pnumber}
                            name={this.state.eventBasedMemberList[i].fname + ' ' + this.state.eventBasedMemberList[i].lname}
                            birthday={this.state.eventBasedMemberList[i].DOB.split("-")[2]}
                            checked={this.state.eventBasedMemberList[i].ordered}
                            type={this.state.eventBasedMemberList[i].event}
                            handleChange={this.handleChange.bind(this)}
                            id={i}
                        />
                    )
                }
            }
            // If orders already placed this year, don't allow placing orders again and notify user
            // if (false) {
            if (ordersThisYear && ordersYear.toString() === year) {
                toaster.warning("You've already placed orders for this quarter. Please choose a different quarter and/or year and try again or contact your administrator to make changes to your orders.", {duration: 5})
                this.setState({
                    isShowOrderFormLoading: true,
                    check: {
                        checkNumber: "",
                        checkDate: "",
                        checkAmount: ""
                    },
                    credit: {
                        cardName: "",
                        cardNumber: "",
                        cardExp: "",
                        cardCvv: "",
                        cardZip: "",
                        cardAmount: "",
                        cardFees: false,
                        cardRecurringDonation: false,
                        cardReceiptEmail: "",
                    },
                    quarterMonths: [],
                    eventBasedMemberList: [],
                    existingOrders: [],
                    donationCount: 0,
                    donationsByMonthcount: [0,0,0],
                    showPlaceOrdersModal: false,
                    saveOrdersLoading: false,
                    ordersLoading: false,
                    isShowOrderFormLoading: false,
                    paymentType: "Credit Card",
                    results_1: [],
                    results_2: [],
                    results_3: [],
                    donorName: '',
                })
            }
            // If orders not placed this year, but placed last year, show last year's orders and notify user
            if (ordersThisYear && (ordersYear + 1).toString() === year) {
                toaster.warning("No wishes have been made this year, so we're showing the wishes that were made last year.", {duration: 5})
            }
            ordersYear = ordersYear - 1
        }
        (ordersThisYear && ordersYear.toString() === year) && toaster.notify("Now select the people you want to wish well and press the 'Make Wishes' button at the bottom of this page.", {duration: 10})
        this.setState({
            isShowOrderFormLoading: false,
            ordersLoading: false
        })
        this.forceUpdate()
    }
    // Function: save new order form
    completeTransaction = async () => {
        if (this.state.oneOff) {
            mixpanel.track('Oneoff donation completed');
            const donor = this.state.credit.cardOneOffDonorName
            // const donor = parseInt(this.state.donorSelected.split('(')[1].split(')')[0])
            let quarter = document.getElementById("quarter").value
            let year = document.getElementById("year").value

            let cardAmount

            if (this.state.paymentType === "Credit Card") {

                // TODO: Charge card and return auth #, date, and amount to create orders after successful
                cardAmount = this.state.chargeAmount
            }

            // Create orders
            try {
                var wishCount = 0
                var orders = []
                wishCount++
                orders.push(this.state.eventBasedMemberList[i])
                var apiName = 'publicorderform';
                var path = '/publicorderform/createorder';
                var chargeDate = new Date().toISOString().split('T')[0]
                var myInit = { // OPTIONAL
                    body: {
                        id: uuid(),
                        donorName: donor,
                        receiverPNumber: this.state.oneOffDonationReciever,
                        year: parseInt(year),
                        paymentType: 'Credit Card',
                        authorizationNumber: this.state.credit.cardFees ? 'Oneoff donation - fees covered' : 'Oneoff donation - fees not covered',
                        amount: cardAmount,
                        date: chargeDate,
                        organizationId: this.state.orgSettings.organizationId
                    },
                    headers: {}, // OPTIONAL
                };

                await API.put(apiName, path, myInit);
                
                // Get USD amount
                const url = `https://v6.exchangerate-api.com/v6/dbd09b718701f4c8136ef682/pair/${this.state.countryCurrency}/USD/${parseFloat(cardAmount).toFixed(2)}`;
                const response = await fetch(`${url}`)
                const data = await response.json();
                const amount = data.conversion_result.toFixed(2).toString();

                const orgName = this.state.orgSettings.chapterName

                const today = new Date().toISOString()
                const todayDate = today.split('T')[0]
                const receivers = [this.state.oneOffDonationReciever]
                let newTransaction = {
                    transactionDate: todayDate,
                    quarter: quarter,
                    year: year,
                    wishType: 'WishWellCreditCardWishes-OneOff',
                    donorPNumber: donor,
                    donorFName: this.state.credit.cardOneOffDonorName,
                    donorLName: null,
                    donorEmail: this.state.credit.cardReceiptEmail,
                    donorAddress: null,
                    donorPhone: null,
                    receiverPNumbers: receivers,
                    localCurrency: this.state.countryCurrency,
                    localAmount: cardAmount ? cardAmount : this.state.donationCount,
                    amount: amount,
                    transactionNumber: 'WishWellCreditCardWishes-OneOff',
                    paymentType: 'Credit Card',
                    organizationId: this.state.orgSettings.organizationId,
                    organizationName: orgName,
                }

                // Removed transaction check, because we don't allow creating orders twice on the makeawish side
                // if (TRANSACTIONS.length > 0) {
                if (false) {
                    // update transaction
                    // newTransaction.id = TRANSACTIONS[0].id
                    apiName = 'publicorderform';
                    path = '/publicorderform/updatetransaction';
                    myInit = { // OPTIONAL
                        body: {
                            transaction: newTransaction
                        },
                        headers: {}, // OPTIONAL
                    };
                    await API.put(apiName, path, myInit);
                    // Tracking
                    mixpanel.track('Makeawish order updated');
                }
                else {
                    // create transaction
                    newTransaction.id = uuid();
                    apiName = 'publicorderform';
                    path = '/publicorderform/createtransaction';
                    myInit = { // OPTIONAL
                        body: {
                            transaction: newTransaction
                        },
                        headers: {}, // OPTIONAL
                    };
                    await API.put(apiName, path, myInit);
                    mixpanel.track('Makeawish order created');
                    if (this.state.credit.cardFees) {
                        mixpanel.track('Makeawish fees covered');
                    }

                }

                const donatedAmount = cardAmount ? cardAmount : this.state.donationCount

                // Mark URL as done
                const searchParams = new URLSearchParams(window.location.search);
                searchParams.set('reciever', 'done');
                const updatedSearchString = searchParams.toString();
                const newUrl = `${window.location.origin}${window.location.pathname}?${updatedSearchString}`;
                window.history.replaceState(null, '', newUrl);
                
                window.location.reload()

                this.setState({
                    quarterMonths: [],
                    eventBasedMemberList: [],
                    existingOrders: [],
                    showPlaceOrdersModal: false,
                    saveOrdersLoading: false,
                    results_1: [],
                    results_2: [],
                    results_3: [],
                    donorPNumber: "",
                    donor: {},
                check: {
                    checkNumber: "",
                    checkDate: "",
                    checkAmount: ""
                },
                credit: {
                    cardName: "",
                    cardNumber: "",
                    cardExp: "",
                    cardCvv: "",
                    cardZip: "",
                    cardAmount: "",
                    cardFees: false,
                    cardRecurringDonation: false,
                    cardReceiptEmail: "",
                },
                donationCount: 0,
                donationsByMonthcount: [0,0,0],
                showCheckoutModal: false,
                showOneOffDonationModal: false,
                oneOffLoading: false,
                orderComplete: this.state.credit.cardReceipt ? true : false,
                })
            } catch (error) {
                Bugsnag.notify(error);
                console.log(error)
            }
        }
        else if (!this.state.toDonate) {
            mixpanel.people.set({
                'Donor Status': 'Activated',
            });
            mixpanel.people.set_once({
                'Donor Activation Date': new Date().toISOString(),
            })
            mixpanel.track('Makeawish donation completed');
            const donor = parseInt(this.state.donorSelected.split('(')[1].split(')')[0])
            let quarter = document.getElementById("quarter").value
            let year = document.getElementById("year").value

            let cardAmount

            if (this.state.paymentType === "Credit Card") {

                // TODO: Charge card and return auth #, date, and amount to create orders after successful
                cardAmount = this.state.chargeAmount
            }

            // Create orders
            try {
                var cardReceipt = []
                var wishCount = 0
                var orders = []
                for (var i = 0; i < this.state.eventBasedMemberList.length; i++) {
                    // Delete removed/un-checked orders from table
                    if (!this.state.eventBasedMemberList[i].ordered) {
                        // for (var j = 0; j < this.state.existingOrders.length; j++) {
                        //     if (this.state.eventBasedMemberList[i].pnumber === this.state.existingOrders[j].receiverPNumber) {
                        //         // await API.graphql(graphqlOperation(deleteOrder, {input: { id: this.state.existingOrders[j].id, }}))
                        //     }
                        // }
                    }
                    // Add new orders to table
                    else if (this.state.eventBasedMemberList[i].ordered) {
                        wishCount++
                        orders.push(this.state.eventBasedMemberList[i])
                        const apiName = 'publicorderform';
                        var path = '/publicorderform/createorder';
                        chargeDate = new Date().toISOString().split('T')[0]
                        myInit = { // OPTIONAL
                            body: {
                                id: uuid(),
                                donorPNumber: donor,
                                receiverPNumber: this.state.eventBasedMemberList[i].pnumber,
                                year: parseInt(year),
                                paymentType: 'Credit Card',
                                authorizationNumber: this.state.credit.cardFees ? 'WishWellCreditCardWishes - fees covered' : 'WishWellCreditCardWishes - fees not covered',
                                amount: cardAmount,
                                date: chargeDate,
                                organizationId: this.state.eventBasedMemberList[i].organizationId
                            },
                            headers: {}, // OPTIONAL
                        };
                        await API.put(apiName, path, myInit);
                    }
                }

                // Check if transaction exists - this isn't necessary, because we don't allow creating orders twice on the makeawish side
                // var apiName = 'publicorderform';
                // path = '/publicorderform/gettransactions';
                // myInit = { // OPTIONAL
                //     body: {
                //         donorPNumber: donor,
                //         quarter: quarter, 
                //         year: parseInt(year),
                //         paymentType: 'Credit Card',
                //         organizationId: this.state.donor.organizationId
                //     },
                //     headers: {}, // OPTIONAL
                // };

                // const transactions = await API.post(apiName, path, myInit);
                // var TRANSACTIONS = transactions.body

                var apiName = 'publicorderform';
                path = '/publicorderform/getorgbyid';
                myInit = { // OPTIONAL
                    body: {
                        organizationId: this.state.donor.organizationId
                    },
                    headers: {}, // OPTIONAL
                };

                const orgNameResponse = await API.post(apiName, path, myInit);
                const orgName = orgNameResponse.body

                await API.post(apiName, path, myInit);

                // Get USD amount
                const url = `https://v6.exchangerate-api.com/v6/dbd09b718701f4c8136ef682/pair/${this.state.countryCurrency}/USD/${parseFloat(cardAmount).toFixed(2)}`;
                const response = await fetch(`${url}`)
                const data = await response.json();
                const amount = data.conversion_result.toFixed(2).toString();

                const today = new Date().toISOString()
                const todayDate = today.split('T')[0]
                const receivers = this.state.eventBasedMemberList.filter(res=>res.ordered).map(ele=>ele.pnumber);

                let newTransaction = {
                    transactionDate: todayDate,
                    quarter: quarter,
                    year: year,
                    wishType: 'WishWellCreditCardWishes',
                    donorPNumber: donor,
                    donorFName: this.state.donor.fname,
                    donorLName: this.state.donor.lname,
                    donorEmail: this.state.donor.email,
                    donorAddress: this.state.donor.address1,
                    donorPhone: this.state.donor.phone,
                    receiverPNumbers: receivers,
                    localCurrency: this.state.countryCurrency,
                    localAmount: cardAmount ? cardAmount : this.state.donationCount,
                    amount: amount,
                    transactionNumber: 'WishWellCreditCardWishes',
                    paymentType: 'Credit Card',
                    organizationId: this.state.donor.organizationId,
                    organizationName: orgName[0].name,
                }

                // Removed transaction check, because we don't allow creating orders twice on the makeawish side
                // if (TRANSACTIONS.length > 0) {
                if (false) {
                    // update transaction
                    // newTransaction.id = TRANSACTIONS[0].id
                    apiName = 'publicorderform';
                    path = '/publicorderform/updatetransaction';
                    myInit = { // OPTIONAL
                        body: {
                            transaction: newTransaction
                        },
                        headers: {}, // OPTIONAL
                    };
                    await API.put(apiName, path, myInit);
                    // Tracking
                    mixpanel.track('Makeawish order updated');
                }
                else {
                    // create transaction
                    newTransaction.id = uuid();
                    apiName = 'publicorderform';
                    path = '/publicorderform/createtransaction';
                    myInit = { // OPTIONAL
                        body: {
                            transaction: newTransaction
                        },
                        headers: {}, // OPTIONAL
                    };
                    await API.put(apiName, path, myInit);
                    mixpanel.track('Makeawish order created');
                    if (this.state.credit.cardFees) {
                        mixpanel.track('Makeawish fees covered');
                    }

                }

                const donatedAmount = cardAmount ? cardAmount : this.state.donationCount
                cardReceipt.push(["Donor Name: " + this.state.donorName])
                cardReceipt.push(["Quarter and year: " + quarter + " " + year])
                cardReceipt.push(["Total donated: $" + donatedAmount])
                cardReceipt.push(["Number of wishes made: " + wishCount])
                cardReceipt.push([" "])
                cardReceipt.push(["Receiver Name - Event Type - Event Date"]);
                
                for (i = 0; i < orders.length; i++) {
                    const date = new Date(orders[i].DOB);
                    const formattedDate = date.toLocaleDateString('en-US', {
                        month: '2-digit',
                        day: '2-digit',
                    });
                    cardReceipt.push(`${orders[i].fname} ${orders[i].lname} - ${orders[i].event} - ${formattedDate}`);
                }

                path = '/publicorderform/sendReceiptEmail';
                myInit = { // OPTIONAL
                    body: {
                        member: this.state.donor,
                        orgSettings: this.state.orgSettings,
                        orderDetails: cardReceipt
                    },
                    headers: {}, // OPTIONAL
                };
                const emailResponse = await API.post(apiName, path, myInit);

                this.setState({ cardReceipt: cardReceipt })

                if (!this.state.credit.cardReceipt) {

                    const searchParams = new URLSearchParams(window.location.search);
                    searchParams.append('wishes', 'done');
                    const updatedSearchString = searchParams.toString();
                    const newUrl = `${window.location.origin}${window.location.pathname}?${updatedSearchString}`;
                    window.history.replaceState(null, '', newUrl);
                    window.location.reload()
                }

                this.setState({
                    quarterMonths: [],
                    eventBasedMemberList: [],
                    existingOrders: [],
                    showPlaceOrdersModal: false,
                    saveOrdersLoading: false,
                    results_1: [],
                    results_2: [],
                    results_3: [],
                    donorPNumber: "",
                    donor: {},
                check: {
                    checkNumber: "",
                    checkDate: "",
                    checkAmount: ""
                },
                credit: {
                    cardName: "",
                    cardNumber: "",
                    cardExp: "",
                    cardCvv: "",
                    cardZip: "",
                    cardAmount: "",
                    cardFees: false,
                    cardRecurringDonation: false,
                    cardReceiptEmail: "",
                },
                donationCount: 0,
                donationsByMonthcount: [0,0,0],
                showCheckoutModal: false,
                showOneOffDonationModal: false,
                oneOffLoading: false,
                orderComplete: this.state.credit.cardReceipt ? true : false,
                })
            } catch (error) {
                Bugsnag.notify(error);
                console.log(error)
            }
        }
        else {
            mixpanel.track('Donation completed');
            const donor = this.state.credit.cardReceiptEmail

            let quarter = document.getElementById("quarter").value
            let year = document.getElementById("year").value

            let cardAmount

            if (this.state.paymentType === "Credit Card") {

                // TODO: Charge card and return auth #, date, and amount to create orders after successful
                cardAmount = this.state.chargeAmount
            }

            // Create orders
            try {
                var wishCount = 0
                var orders = []
                wishCount++
                orders.push(this.state.eventBasedMemberList[i])
                var apiName = 'publicorderform';
                var path = '/publicorderform/createorder';
                var chargeDate = new Date().toISOString().split('T')[0]
                var myInit = { // OPTIONAL
                    body: {
                        id: uuid(),
                        donorName: donor,
                        receiverPNumber: 'None - Donation',
                        year: parseInt(year),
                        paymentType: 'Credit Card',
                        authorizationNumber: this.state.credit.cardFees ? 'Donation - fees covered' : 'Donation - fees not covered',
                        amount: cardAmount,
                        date: chargeDate,
                        organizationId: this.state.orgSettings.organizationId
                    },
                    headers: {}, // OPTIONAL
                };

                await API.put(apiName, path, myInit);
                
                // Get USD amount
                const url = `https://v6.exchangerate-api.com/v6/dbd09b718701f4c8136ef682/pair/${this.state.countryCurrency}/USD/${parseFloat(cardAmount).toFixed(2)}`;
                const response = await fetch(`${url}`)
                const data = await response.json();
                const amount = data.conversion_result.toFixed(2).toString();

                const orgName = this.state.orgSettings.chapterName

                const today = new Date().toISOString()
                const todayDate = today.split('T')[0]
                const receivers = ['None - Donation']
                let newTransaction = {
                    transactionDate: todayDate,
                    quarter: quarter,
                    year: year,
                    wishType: 'WishWellCreditCardWishes-Donation',
                    donorPNumber: donor,
                    donorFName: this.state.credit.cardReceiptEmail,
                    donorLName: null,
                    donorEmail: this.state.credit.cardReceiptEmail,
                    donorAddress: null,
                    donorPhone: null,
                    receiverPNumbers: ['None - Donation'],
                    localCurrency: this.state.countryCurrency,
                    localAmount: cardAmount ? cardAmount : this.state.donationCount,
                    amount: amount,
                    transactionNumber: 'WishWellCreditCardWishes-Donation',
                    paymentType: 'Credit Card',
                    organizationId: this.state.orgSettings.organizationId,
                    organizationName: orgName,
                }

                // Removed transaction check, because we don't allow creating orders twice on the makeawish side
                // if (TRANSACTIONS.length > 0) {
                if (false) {
                    // update transaction
                    // newTransaction.id = TRANSACTIONS[0].id
                    apiName = 'publicorderform';
                    path = '/publicorderform/updatetransaction';
                    myInit = { // OPTIONAL
                        body: {
                            transaction: newTransaction
                        },
                        headers: {}, // OPTIONAL
                    };
                    await API.put(apiName, path, myInit);
                    // Tracking
                    mixpanel.track('Makeawish order updated');
                }
                else {
                    // create transaction
                    newTransaction.id = uuid();
                    apiName = 'publicorderform';
                    path = '/publicorderform/createtransaction';
                    myInit = { // OPTIONAL
                        body: {
                            transaction: newTransaction
                        },
                        headers: {}, // OPTIONAL
                    };
                    await API.put(apiName, path, myInit);
                    mixpanel.track('Makeawish order created');
                    if (this.state.credit.cardFees) {
                        mixpanel.track('Makeawish fees covered');
                    }

                }

                const donatedAmount = cardAmount ? cardAmount : this.state.donationCount

                // Mark URL as done
                const searchParams = new URLSearchParams(window.location.search);
                searchParams.set('reciever', 'done');
                const updatedSearchString = searchParams.toString();
                const newUrl = `${window.location.origin}${window.location.pathname}?${updatedSearchString}`;
                window.history.replaceState(null, '', newUrl);
                
                window.location.reload()

                this.setState({
                    quarterMonths: [],
                    eventBasedMemberList: [],
                    existingOrders: [],
                    showPlaceOrdersModal: false,
                    saveOrdersLoading: false,
                    results_1: [],
                    results_2: [],
                    results_3: [],
                    donorPNumber: "",
                    donor: {},
                check: {
                    checkNumber: "",
                    checkDate: "",
                    checkAmount: ""
                },
                credit: {
                    cardName: "",
                    cardNumber: "",
                    cardExp: "",
                    cardCvv: "",
                    cardZip: "",
                    cardAmount: "",
                    cardFees: false,
                    cardRecurringDonation: false,
                    cardReceiptEmail: "",
                },
                donationCount: 0,
                donationsByMonthcount: [0,0,0],
                showCheckoutModal: false,
                showOneOffDonationModal: false,
                donateNowModalShown: false,
                donateNowModalLoading: false,
                oneOffLoading: false,
                orderComplete: this.state.credit.cardReceipt ? true : false,
                })
            } catch (error) {
                Bugsnag.notify(error);
                console.log(error)
            }
        }
    }

    closeCheckoutEarly() {
        this.setState({
            showCheckoutModal: false,
            showOneOffDonationModal: false,
            saveOrdersLoading: false,
            oneOffLoading: false,
        })
        mixpanel.track('Makeawish checkout closed early');
    }

    // Checkout to Stripe
    checkout = async () => {
        let quarter = document.getElementById("quarter").value
        let year = document.getElementById("year").value
        if (this.state.credit.cardAmount < this.state.orgSettings.minWishPrice * this.state.donationCount) {
            toaster.warning("You must donate at least" + this.state.currencySymbol + this.state.orgSettings.minWishPrice + " per wish. Please enter a donation amount of at least " + this.state.currencySymbol + this.state.orgSettings.minWishPrice * this.state.donationCount, {duration: 5})
            return
        }
        this.state.oneOff ? mixpanel.track("Oneoff checkout button pressed") : mixpanel.track('Makeawish checkout button pressed');
        this.state.credit.cardFees && this.state.oneOff ? mixpanel.track("Oneoff fees covered") : mixpanel.track('Makeawish fees covered')
        this.state.credit.cardReceipt && mixpanel.track('Makeawish receipt requested')

        // Create payment intent and pass items to checkout (recurring on one time)
        const apiName = 'wishwellpayments';
        var path = ''
        this.state.chargeAmount = this.state.credit.cardFees ? (this.state.credit.cardAmount * 1.035) + .5 : this.state.credit.cardAmount
        var myInit = {}

        // Get org of member
        const orgApiName = 'publicorderform';
        const orgPath = '/publicorderform/getorgbyid';
        const orgMyInit = { // OPTIONAL
            body: {
                organizationId: this.state.donor.organizationId
            },
            headers: {}, // OPTIONAL
        };

        const orgNameResponse = await API.post(orgApiName, orgPath, orgMyInit);
        const org = orgNameResponse.body

        if (org[0].parentOrgId === "859d0bb4-21c4-4c0d-9804-126093d78552") {
            path = '/payments/createPAPPaymentIntent';
            myInit = { // OPTIONAL
                body: {
                    amount: (Math.round(this.state.chargeAmount * 100)),
                    currency: "usd",
                    receipt_email: this.state.credit.cardReceiptEmail,
                    // TODO: add list of wishes too
                    description: this.state.oneOff ? this.state.credit.cardOneOffDonorName + "'s birthday donation for " + this.state.donorName : this.state.donor.fname + "'s " + quarter + " " + year + " wishes for " + this.state.orgSettings.chapterName,
                    statement_descriptor_suffix: this.state.orgSettings.makeAWishURL,
                    metadata: {}
                    // metadata: {
                    //     donor: JSON.stringify(this.state.donor),
                    //     eventBasedMemberList: JSON.stringify(this.state.eventBasedMemberList),
                    //     existingOrders: JSON.stringify(this.state.existingOrders),
                    //     quarter: quarter,
                    //     year: year,
                    //     type: type,
                    // }
                },
                headers: {}, // OPTIONAL
            };
        }
        else {
            if (!this.state.donor.eReminders) {
                if (window.confirm("You have not signed up to get emailed reminders for each wish you've made. Would you like to sign up now?")) {
                    var updatedMember = this.state.donor
                    updatedMember.eReminders = true
                    var publicOrderFormApiName = 'publicorderform';
                    var publicOrderFormPath = '/publicorderform/updatemember';
                    myInit = { // OPTIONAL
                        body: {
                            member: updatedMember
                        },
                        headers: {}, // OPTIONAL
                    };
                    try {
                        await API.put(publicOrderFormApiName, publicOrderFormPath, myInit);
                    } catch (error) {
                        console.log(error)
                        Bugsnag.notify(error);
                    }
                }
            }
            this.setState({
                stripePromise: env === 'dev' ? 
                    loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY_TEST, {stripeAccount: this.state.orgSettings.stripeAccountId}) : 
                    loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY_LIVE, {stripeAccount: this.state.orgSettings.stripeAccountId}),
            })
            path = '/payments/createPaymentIntent';
            myInit = { // OPTIONAL
                body: {
                    amount: (Math.round(this.state.chargeAmount * 100)),
                    currency: this.state.countryCurrency,
                    receipt_email: this.state.credit.cardReceiptEmail,
                    // TODO: add list of wishes too
                    description: this.state.oneOff ? this.state.credit.cardOneOffDonorName + "'s birthday donation for " + this.state.donorName : this.state.donor.fname + "'s " + quarter + " " + year + " wishes for " + this.state.orgSettings.chapterName,
                    statement_descriptor_suffix: this.state.orgSettings.makeAWishURL,
                    account_id: this.state.orgSettings.stripeAccountId,
                    metadata: {}
                    // metadata: {
                    //     donor: JSON.stringify(this.state.donor),
                    //     eventBasedMemberList: JSON.stringify(this.state.eventBasedMemberList),
                    //     existingOrders: JSON.stringify(this.state.existingOrders),
                    //     quarter: quarter,
                    //     year: year,
                    //     type: type,
                    // }
                },
                headers: {}, // OPTIONAL
            };
        }
        

        try {
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(this.state.credit.cardReceiptEmail)) {
                toaster.warning("Please provide a valid email where we can send you your receipt.", {duration: 5})
                return
            }
            if (this.state.credit.cardAmount === "") {
                toaster.warning("Please enter a donation amount.", {duration: 5})
                return
            }
            this.setState({
                saveOrdersLoading: true,
                oneOffLoading: true,
                showPlaceOrdersModal: false,
                showOneOffDonationModal: false,
            })

            const response = await API.post(apiName, path, myInit);

            this.setState({
                stripeOptions: {clientSecret: response.body.client_secret},
                showCheckoutModal: true
            })
            // this.saveOrders()
        } catch (error) {
            Bugsnag.notify(error);
            console.log(error)
        }
    }

    // Checkout to Stripe for donation
    checkoutForDonation = async () => {
        mixpanel.track("Donation checkout button pressed")
        this.state.credit.cardFees ? mixpanel.track("Donation fees covered") : mixpanel.track('Donation fees not covered')

        // Create payment intent and pass items to checkout (recurring on one time)
        var apiName = 'wishwellpayments';
        var path = ''
        this.state.chargeAmount = this.state.credit.cardFees ? (this.state.credit.cardAmount * 1.035) + .5 : this.state.credit.cardAmount
        var myInit = {}

        this.setState({
            stripePromise: env === 'dev' ? 
                loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY_TEST, {stripeAccount: this.state.orgSettings.stripeAccountId}) : 
                loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY_LIVE, {stripeAccount: this.state.orgSettings.stripeAccountId}),
        })
        if (this.state.credit.cardRecurringDonation) {
            var customer_payment_id = ""
            apiName = 'wishwellpayments';
            path = '/payments/createcustomer';
            myInit = { // OPTIONAL
                body: {
                    user_name: this.state.credit.cardReceiptEmail,
                    user_email: this.state.credit.cardReceiptEmail,
                    account_id: this.state.orgSettings.stripeAccountId,
                },
                headers: {}, // OPTIONAL
            };

            try {
                const response = await API.post(apiName, path, myInit);
                customer_payment_id = response.body
            } catch (error) {
                console.log(error)
            }
            // Get priceID
            path = '/payments/createProduct';
            myInit = { // OPTIONAL
                body: {
                amount: (Math.round(this.state.chargeAmount * 100)),
                currency: this.state.countryCurrency,
                // TODO: add list of wishes too
                description: "Recurring gift from " + this.state.credit.cardReceiptEmail + " to " + this.state.orgSettings.chapterName,
                account_id: this.state.orgSettings.stripeAccountId,
                },
                headers: {}, // OPTIONAL
            };

            const price_response = await API.post(apiName, path, myInit);

            path = '/payments/createSubscription';
            myInit = { // OPTIONAL
                body: {
                priceID: (price_response.body.default_price.id),
                currency: this.state.countryCurrency,
                receipt_email: this.state.credit.cardReceiptEmail,
                // TODO: add list of wishes too
                description: "Recurring gift from " + this.state.credit.cardReceiptEmail + " to " + this.state.orgSettings.chapterName, 
                customer_payment_id: customer_payment_id, 
                account_id: this.state.orgSettings.stripeAccountId
                },
                headers: {}, // OPTIONAL
            };
        }
        else {
            path = '/payments/createPaymentIntent';
            myInit = { // OPTIONAL
                body: {
                    amount: (Math.round(this.state.chargeAmount * 100)),
                    currency: this.state.countryCurrency,
                    receipt_email: this.state.credit.cardReceiptEmail,
                    // TODO: add list of wishes too
                    description: "Gift from " + this.state.credit.cardReceiptEmail + " to " + this.state.orgSettings.chapterName,
                    statement_descriptor_suffix: this.state.orgSettings.makeAWishURL,
                    account_id: this.state.orgSettings.stripeAccountId,
                    metadata: {}
                    // metadata: {
                    //     donor: JSON.stringify(this.state.donor),
                    //     eventBasedMemberList: JSON.stringify(this.state.eventBasedMemberList),
                    //     existingOrders: JSON.stringify(this.state.existingOrders),
                    //     quarter: quarter,
                    //     year: year,
                    //     type: type,
                    // }
                },
                headers: {}, // OPTIONAL
            };
        }
        
        try {
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(this.state.credit.cardReceiptEmail)) {
                toaster.warning("Please provide a valid email where we can send you your receipt.", {duration: 5})
                return
            }
            if (this.state.credit.cardAmount === "") {
                toaster.warning("Please enter a donation amount.", {duration: 5})
                return
            }
            this.setState({
                saveOrdersLoading: true,
                oneOffLoading: true,
                showPlaceOrdersModal: false,
                showOneOffDonationModal: false,
                donateNowModalShown: false,
                donateNowModalLoading: false,

            })

            const response = await API.post(apiName, path, myInit);

            console.log(response.body)
            this.setState({
                stripeOptions: {clientSecret: this.state.credit.cardRecurringDonation ? response.body.latest_invoice.payment_intent.client_secret : response.body.client_secret},
                showCheckoutModal: true
            })
            // this.saveOrders()
        } catch (error) {
            Bugsnag.notify(error);
            console.log(error)
        }
    }

    // Make wishes
    makeWishes() {
        if (this.state.donationCount === 0) {
            if (window.confirm("You haven't selected any wishes. Are you sure you want to continue making a donation?")) {
                this.setState({
                    showPlaceOrdersModal: true
                })
            }
        }
        else if (this.state.selectedYear < this.state.currentYear || (this.state.selectedYear === this.state.currentYear && this.state.selectedQuarter <= this.state.currentQuarter)) {
            if (window.confirm("You are placing orders for a current or previous quarter, and wishes for events that have already passed may not be sent. Are you sure you want to continue?")) {
                this.setState({
                    showPlaceOrdersModal: true
                })
            
            }
        }
        else {
            mixpanel.track('Make wishes button pressed');
            this.setState({
                showPlaceOrdersModal: true
            })
        }
    }

  render() {
    if (this.state.isLoading) {
        // if (false) {
        return(
            <div>
                <Pane display="flex" flexDirection="column" alignItems="center" justifyContent="center" height={400}>
                    <img src={logo} alt="WishWell by OneGift" style={{ textAlign: "center", paddingTop: '100px', width: '30%', paddingBottom: '20px' }} />
                    <div className="progress-bar">
                        <div className="progress-bar-fill"></div>
                    </div>
                </Pane>
                <footer style={{
                  display: "flex",
                  margin: "auto",
                  justifyContent: 'center',
                  alignItems: "center",
                  backgroundColor: '#0a003a',
                  position: "fixed",
                  bottom: "0px",
                  height: "50px",
                  width: "100%",
                  zIndex: 100, 
                  color: 'white',
                  paddingTop: '10px',
                  paddingBottom: '10px' 
                }}>
                  <p>WishWell was created by OneGift | <a href="https://onegift.ai/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>Learn more</a> | <a href="mailto:experience@onegift.ai" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>experience@onegift.ai</a>&nbsp; | +1 (951) 6ONEGIFT | <a href="https://onegift.ai/the-legal-stuff" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>Legal Terms</a></p>
                </footer>
            </div>
        )
    }
    else if (this.state.orderComplete) {
        // else if (true) {
        return(
            <div>
                {this.navBar()}
                <div style={{ textAlign: "center", margin: "1em", paddingTop: '130px' }}>
                    <CSVLink 
                        data={this.state.cardReceipt} 
                        filename={this.state.donorName + '\'s Wishes.csv'}
                        onClick={() => {
                            mixpanel.track('Makeawish orders receipt downloaded');
                        }}
                    >Download Orders Receipt</CSVLink>
                    <br></br>
                    <br></br>
                    <Button style={{ marginBottom: '60px' }} onClick={() => window.location.reload()}>Make More Wishes</Button>
                </div>
                <footer style={{
                  display: "flex",
                  margin: "auto",
                  justifyContent: 'center',
                  alignItems: "center",
                  backgroundColor: '#0a003a',
                  position: "fixed",
                  bottom: "0px",
                  height: "50px",
                  width: "100%",
                  zIndex: 100, 
                  color: 'white',
                  paddingTop: '10px',
                  paddingBottom: '10px' 
                }}>
                  <p>WishWell was created by OneGift | <a href="https://onegift.ai/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>Learn more</a> | <a href="mailto:experience@onegift.ai" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>experience@onegift.ai</a>&nbsp; | +1 (951) 6ONEGIFT | <a href="https://onegift.ai/the-legal-stuff" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>Legal Terms</a></p>
                </footer>
            </div>
        )
    }
    else {
      return (
        <div>
            {this.navBar()}
            <div style={{ textAlign: "center", margin: "1em" }}>
                <h2 style={{ paddingTop: '100px' }}>{this.state.nonProfit ? this.state.orgSettings.chapterName : this.state.orgSettings.chapterName + ' for ' + this.state.orgSettings.charityPartner}</h2>
                <br></br>
            <div>
                <div style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                    <table><tbody>
                        <tr>
                        <td>
                            <SelectField width="140px" name="quarter" id="quarter" value={this.state.selectedQuarter} onChange={this.handleChange}>
                                <option value="Q1">Q1</option>
                                <option value="Q2">Q2</option>
                                <option value="Q3">Q3</option>
                                <option value="Q4">Q4</option>
                            </SelectField>
                        </td>
                        <td>
                            <SelectField width="140px" name="year" id="year" value={this.state.selectedYear} onChange={this.handleChange}>
                            {[...Array(4)].map((_, index) => {
                                const year = this.state.currentYear - 1 + index;
                                return (
                                <option key={year} value={year}>{`${year}`}</option>
                                );
                            })}
                            </SelectField>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3}>
                        <label>Donor Name</label>
                        <br></br>
                        <Autocomplete
                            title="Name (ID)"
                            id="donorName"
                            onChange={changedItem => this.setState({ donorSelected: changedItem })}
                            // onChange={changedItem => console.log(changedItem)}
                            items={this.state.allMembers.map( e => e.fname + ' ' + e.lname + ' (' + e.pnumber + ')')}
                            >
                            {props => {
                                const { getInputProps, getRef, inputValue } = props
                                return (
                                <TextInput
                                    placeholder="Name"
                                    value={inputValue}
                                    ref={getRef}
                                    {...getInputProps()}
                                />
                                )
                            }}
                        </Autocomplete>
                        <br></br>
                            {/* <TextInputField inputWidth="200px" label="Donor PAP Number" type="number" id="donorPNumber" name="donorPNumber" value={this.state.donorPNumber} onChange={this.handleChange} onKeyPress={this.handleKeyPressed} ref={inputEl => (this.donorInput = inputEl)}></TextInputField> */}
                            <Button isLoading={this.state.isShowOrderFormLoading} iconBefore={SearchTemplateIcon} type="Button" onClick={() => {this.showOrderForm()}}>Select Wishes</Button>
                        </td>
                        </tr>
                    </tbody></table>
                </div>
                <div>
                    <br></br>
                    {this.state.ordersLoading && <div>
                        <Pane display="flex" flexDirection="column" alignItems="center" justifyContent="center" height={200}>
                            <img src={logo} alt="WishWell by OneGift" style={{ textAlign: "center", paddingTop: '10px', width: '20%', paddingBottom: '20px' }} />
                            <div className="progress-bar" style={{ width: '20%' }}>
                                <div className="progress-bar-fill"></div>
                            </div>
                        </Pane>
                    </div>}
                    {this.state.donorName !== "" && !this.state.ordersLoading && !this.state.oneOff && 
                    <div>
                        <h1 id="donorName">{this.state.donorName} - {this.state.donationCount} Donations</h1>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <h2>Birthdays</h2>
                            <p style={{ marginLeft: '10px' }}><input type="checkbox" id="selectAll" name="selectAll" checked={this.state.selectAll} onChange={this.handleChange} /> &nbsp;Select All</p>
                        </div>
                    <div style={{ padding: '0 10px' }}> 
                    <table style={{ textAlign: "left", display: "flex", justifyContent: "center", verticalAlign: 'top', borderSpacing: '10px', width: '100%' }}><tbody>
                        <tr style={{ textAlign: "center" }}>
                            <td><h2>{this.state.quarterMonths[0]}{this.state.donorName !== "" && <div>{this.state.eventBasedMemberList.filter(res => res.event === 'Birthday' && res.ordered && res.DOB.split("-")[1] % 3 === 1).length}</div>}</h2></td>
                            <td><h2>{this.state.quarterMonths[1]}{this.state.donorName !== "" && <div>{this.state.eventBasedMemberList.filter(res => res.event === 'Birthday' && res.ordered && res.DOB.split("-")[1] % 3 === 2).length}</div>}</h2></td>
                            <td><h2>{this.state.quarterMonths[2]}{this.state.donorName !== "" && <div>{this.state.eventBasedMemberList.filter(res => res.event === 'Birthday' && res.ordered && res.DOB.split("-")[1] % 3 === 0).length}</div>}</h2></td>
                        </tr>
                        <tr style={{ textAlign: "center", marginTop: "5px" }}>
                            <td><h4>{this.state.eventBasedMemberList.length === 0 ? "" : "Name - Birthday"}</h4></td>
                            <td><h4>{this.state.eventBasedMemberList.length === 0 ? "" : "Name - Birthday"}</h4></td>
                            <td><h4>{this.state.eventBasedMemberList.length === 0 ? "" : "Name - Birthday"}</h4></td>
                        </tr>
                        <tr>
                            <td style={{ verticalAlign: 'top' }}><label htmlFor="ordersPlaced" id="ordersPlaced">{this.state.results_1.filter(res => res.props.type === 'Birthday')}</label></td>
                            <td style={{ verticalAlign: 'top' }}><label htmlFor="ordersPlaced" id="ordersPlaced">{this.state.results_2.filter(res => res.props.type === 'Birthday')}</label></td>
                            <td style={{ verticalAlign: 'top' }}><label htmlFor="ordersPlaced" id="ordersPlaced">{this.state.results_3.filter(res => res.props.type === 'Birthday')}</label></td>
                        </tr>
                    </tbody></table></div></div>}
                    {this.state.eventBasedMemberList.filter(res => res.event === 'Anniversary').length > 0 && 
                    <div>
                        <h2>Anniversaries</h2>
                    <p></p>
                    <table style={{ textAlign: "left", display: "flex", justifyContent: "center", verticalAlign: 'top', borderSpacing: '10px' }}><tbody>
                        <tr style={{ textAlign: "center" }}>
                            <td><h2>{this.state.quarterMonths[0]}{this.state.donorName !== "" && <div>{this.state.eventBasedMemberList.filter(res => res.event === 'Anniversary' && res.ordered && res.DOB.split("-")[1] % 3 === 1).length}</div>}</h2></td>
                            <td><h2>{this.state.quarterMonths[1]}{this.state.donorName !== "" && <div>{this.state.eventBasedMemberList.filter(res => res.event === 'Anniversary' && res.ordered && res.DOB.split("-")[1] % 3 === 2).length}</div>}</h2></td>
                            <td><h2>{this.state.quarterMonths[2]}{this.state.donorName !== "" && <div>{this.state.eventBasedMemberList.filter(res => res.event === 'Anniversary' && res.ordered && res.DOB.split("-")[1] % 3 === 0).length}</div>}</h2></td>
                        </tr>
                        <tr style={{ textAlign: "center", marginTop: "5px" }}>
                            <td><h4>{this.state.eventBasedMemberList.length === 0 ? "" : "Name - Anniversary"}</h4></td>
                            <td><h4>{this.state.eventBasedMemberList.length === 0 ? "" : "Name - Anniversary"}</h4></td>
                            <td><h4>{this.state.eventBasedMemberList.length === 0 ? "" : "Name - Anniversary"}</h4></td>
                        </tr>
                        <tr>
                            <td style={{ verticalAlign: 'top' }}><label htmlFor="ordersPlaced" id="ordersPlaced">{this.state.results_1.filter(res => res.props.type === 'Anniversary')}</label></td>
                            <td style={{ verticalAlign: 'top' }}><label htmlFor="ordersPlaced" id="ordersPlaced">{this.state.results_2.filter(res => res.props.type === 'Anniversary')}</label></td>
                            <td style={{ verticalAlign: 'top' }}><label htmlFor="ordersPlaced" id="ordersPlaced">{this.state.results_3.filter(res => res.props.type === 'Anniversary')}</label></td>
                        </tr>
                    </tbody></table></div>}
                    <br></br>
                    {this.state.eventBasedMemberList.length !== 0 && 
                        <button disabled={this.state.organization && this.state.organization.parentOrgId !== "859d0bb4-21c4-4c0d-9804-126093d78552" && !this.state.orgSettings.stripeOnboardingComplete} style={{ backgroundColor: '#b6eff1', borderRadius: '20px', padding: '10px 20px', marginBottom: '50px' }} onClick={() => this.makeWishes()}>Make Wishes</button>
                    }
                </div>
                {/* Place one-off modal */}
                <Pane>
                    <Dialog
                    isShown={this.state.showOneOffDonationModal}
                    //   isShown={true}
                    shouldCloseOnOverlayClick={false}
                    shouldCloseOnEscapePress={false}
                    onCloseComplete={() => this.setState({ showOneOffDonationModal: false })}
                    title={"How much would you like to gift to " + this.state.orgSettings.chapterName + " on behalf of " + this.state.donorName + "?"}
                    isConfirmLoading={this.state.oneOffLoading}
                    onConfirm={() => this.checkout() }
                    confirmLabel={this.state.oneOffLoading ? 'Making Donation...' : 'Make Donation'}
                    // onCloseComplete={() => this.setState({ showPlaceOrdersModal: false, saveOrdersLoading: false })}
                    >
                    {this.state.paymentType === "Credit Card" && <div>Please enter the donation amount below. You can donate as much as you want, but at least {this.state.orgSettings.minWishPrice.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2,})}.
                    <br></br>
                    <br></br></div>}
                    <br></br>
                    {/* If payment type is credit card */}
                    {this.state.paymentType === "Credit Card" && <div>
                        <table><tbody>
                            <tr><td><TextInputField inputWidth="300" label="Your Name" type="text" id="cardOneOffDonorName" name="cardOneOffDonorName" value={this.state.credit.cardOneOffDonorName} onChange={this.handleChange}></TextInputField></td></tr>
                            <tr><td><TextInputField inputWidth="300" label="Email for receipt" type="text" id="cardReceiptEmail" name="cardReceiptEmail" value={this.state.credit.cardReceiptEmail} onChange={this.handleChange}></TextInputField></td></tr>
                            <tr><td><TextInputField inputWidth="100px" label={"Donation Amount (" + this.state.currencySymbol + ")"} type="number" id="cardAmount" name="cardAmount" value={this.state.credit.cardAmount} onChange={this.handleChange}></TextInputField></td>
                                <td><p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', verticalAlign: 'middle' }}><Checkbox id="cardFees" name="cardFees" checked={this.state.credit.cardFees} onChange={this.handleChange}></Checkbox>&nbsp;I want to cover the processing fees - {(this.state.currencySymbol ? this.state.currencySymbol : "") + ((this.state.credit.cardAmount * .035) + .5).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p></td></tr>
                        </tbody></table>
                    </div>}
                    </Dialog>
                </Pane>
                {/* Place orders modal */}
                <Pane>
                    <Dialog
                    isShown={this.state.showPlaceOrdersModal}
                    //   isShown={true}
                    shouldCloseOnOverlayClick={false}
                    shouldCloseOnEscapePress={false}
                    onCloseComplete={() => this.setState({ showPlaceOrdersModal: false })}
                    title={"Please input payment information for " + this.state.donationCount + ' wishes'}
                    isConfirmLoading={this.state.saveOrdersLoading}
                    onConfirm={() => this.checkout() }
                    confirmLabel={this.state.saveOrdersLoading ? 'Making Wishes...' : 'Make Wishes'}
                    // onCloseComplete={() => this.setState({ showPlaceOrdersModal: false, saveOrdersLoading: false })}
                    >
                    {this.state.paymentType === "Check" && <div>Important: Check information is not required, but if entered, please make sure all information accurate before placing orders to avoid issues with billing or the deposit report. If no amount is entered, default is $1/wish. This action will overwrite any previous orders from this donor for this year.
                    <br></br>
                    <br></br></div>}
                    {this.state.paymentType === "Credit Card" && <div>Please enter the donation amount below. You can donate as much as you want, but at least {this.state.currencySymbol}{this.state.orgSettings.minWishPrice} per wish.
                    <br></br>
                    <br></br></div>}
                    <br></br>
                    {/* If payment type is credit card */}
                    {this.state.paymentType === "Credit Card" && <div>
                        <table><tbody>
                            <tr>    
                                {/* <td><Group>
                                        {this.state.donationsMultiplierOptions.map(({ label, value }) => (
                                            <Button key={label} isActive={this.state.donationsMultiplier === value} onClick={() => this.setState({ donationsMultiplier: value })}>
                                            {label}
                                            </Button>
                                        ))}
                                    </Group></td> */}
                                <td><TextInputField inputWidth="100px" label={"Amount (" + this.state.currencySymbol + ")"} type="number" placeholder={this.state.donationCount * this.state.orgSettings.minWishPrice} id="cardAmount" name="cardAmount" value={this.state.credit.cardAmount} onChange={this.handleChange}></TextInputField></td>
                                <td><p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', verticalAlign: 'middle' }}><Checkbox id="cardFees" name="cardFees" checked={this.state.credit.cardFees} onChange={this.handleChange}></Checkbox>&nbsp;I want to cover the fees - {(this.state.currencySymbol ? this.state.currencySymbol : "") + ((this.state.credit.cardAmount * .035) + .5).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p></td></tr>
                            <tr><td><TextInputField inputWidth="300" label="Email for receipt" type="text" id="cardReceiptEmail" name="cardReceiptEmail" value={this.state.credit.cardReceiptEmail} onChange={this.handleChange}></TextInputField></td>
                            </tr>
                        </tbody></table>
                    </div>}
                    </Dialog>
                </Pane>
                {/* Checkout Dialog */}
                <Pane>
                    <Dialog
                    isShown={this.state.showCheckoutModal}
                    shouldCloseOnOverlayClick={false}
                    shouldCloseOnEscapePress={false}
                    hasHeader={true}
                    hasFooter={false}
                    title={"Complete your gift for " + this.state.orgSettings.chapterName}
                    onCloseComplete={() => this.closeCheckoutEarly()}
                    >
                        <Elements stripe={this.state.stripePromise} options={this.state.stripeOptions}>
                            <br></br>
                            <CheckoutForm amount={this.state.chargeAmount} completeTransaction={this.completeTransaction} orgName={this.state.orgSettings.makeAWishURL} brandColor={this.state.orgSettings.brandColor} currency={this.state.currencySymbol} />
                        </Elements>
                    </Dialog>
                </Pane>
            </div>
            </div>
            <div>
                <footer style={{
                    display: "flex",
                    margin: "auto",
                    justifyContent: 'center',
                    alignItems: "center",
                    backgroundColor: '#0a003a',
                    position: "fixed",
                    bottom: "0px",
                    height: "50px",
                    width: "100%",
                    zIndex: 100, 
                    color: 'white',
                    paddingTop: '10px',
                    paddingBottom: '10px' 
                    }}>
                    <p>WishWell was created by OneGift | <a href="https://onegift.ai/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>Learn more</a> | <a href="mailto:experience@onegift.ai" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>experience@onegift.ai</a>&nbsp; | +1 (951) 6ONEGIFT | <a href="https://onegift.ai/the-legal-stuff" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>Legal Terms</a></p>
                </footer>
            </div>
        {/* Make donation modal */}
        <Pane>
            <Dialog
            isShown={this.state.donateNowModalShown}
            //   isShown={true}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEscapePress={false}
            onCloseComplete={() => this.setState({ donateNowModalShown: false })}
            title={"How much would you like to gift to " + this.state.orgSettings.chapterName}
            isConfirmLoading={this.state.donateNowModalLoading}
            onConfirm={() => this.checkoutForDonation() }
            confirmLabel={this.state.donateNowModalLoading ? 'Packaging Gift...' : 'Gift Now'}
            // onCloseComplete={() => this.setState({ showPlaceOrdersModal: false, saveOrdersLoading: false })}
            >
            <br></br>
            {/* If payment type is credit card */}
            {this.state.paymentType === "Credit Card" && <div>
                <table><tbody>
                    <tr>    
                        {/* <td><Group>
                                {this.state.donationsMultiplierOptions.map(({ label, value }) => (
                                    <Button key={label} isActive={this.state.donationsMultiplier === value} onClick={() => this.setState({ donationsMultiplier: value })}>
                                    {label}
                                    </Button>
                                ))}
                            </Group></td> */}
                        <td><TextInputField inputWidth="100px" label={"Amount (" + this.state.currencySymbol + ")"} type="number" id="cardAmount" name="cardAmount" value={this.state.credit.cardAmount} onChange={this.handleChange}></TextInputField></td>
                        <td><p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', verticalAlign: 'middle' }}><Checkbox id="cardRecurringDonation" name="cardRecurringDonation" checked={this.state.credit.cardRecurringDonation} onChange={this.handleChange}></Checkbox>&nbsp;I want to gift this amount monthly</p></td></tr>
                        <tr><td><TextInputField inputWidth="300" label="Email for receipt" type="text" id="cardReceiptEmail" name="cardReceiptEmail" value={this.state.credit.cardReceiptEmail} onChange={this.handleChange}></TextInputField></td>
                        <td><p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', verticalAlign: 'middle' }}><Checkbox id="cardFees" name="cardFees" checked={this.state.credit.cardFees} onChange={this.handleChange}></Checkbox>&nbsp;I want to cover the fees - {(this.state.currencySymbol ? this.state.currencySymbol : "") + ((this.state.credit.cardAmount * .035) + .5).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p></td></tr>
                        <tr><td colSpan="2"><p style={{ fontStyle: 'italic', fontSize: '12px' }}>* Only gifts to tax exempt organizations are tax deductible.</p></td></tr>
                </tbody></table>
            </div>}
            </Dialog>
        </Pane>
        {/* Dialog to register an account */}
        <div style={{ display: "flex", flexDirection: 'column', width: '300px', alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                <Pane>
                    <Dialog
                        isShown={this.state.registerModalShown}
                        shouldCloseOnOverlayClick={false}
                        shouldCloseOnEscapePress={false}
                        title="WishWell Registration"
                        isConfirmLoading={this.state.registrationLoading}
                        onConfirm={() => { this.setState({ registrationLoading: true }); this.registerMember(); } }
                        confirmLabel={'Sign Up'}
                        onCloseComplete={() => this.setState({ registerModalShown: false, registrationLoading: false })}
                    >
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                        <TextInputField
                            id="fname"
                            key="fname"
                            name="fname"
                            label="First Name"
                            width={300}
                            onChange={(date) => this.handleChange(date, 'member.fname')}
                            type="text"
                        />
                    </div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                            <TextInputField
                                id="lname"
                                key="lname"
                                name="lname"
                                label="Last Name"
                                width={300}
                                onChange={(date) => this.handleChange(date, 'member.lname')}
                                type="text"
                            />
                        </div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                            <TextInputField
                                id="email"
                                key="email"
                                name="email"
                                label="E-mail"
                                width={300}
                                onChange={(date) => this.handleChange(date, 'member.email')}
                                type="text"
                            />
                        </div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                            <TextInputField
                                id="phone"
                                key="phone"
                                name="phone"
                                label="Phone"
                                width={300}
                                onChange={(date) => this.handleChange(date, 'member.phone')}
                                type="tel"
                            />
                        </div>
                        {/* Don't show DOB for GLO */}
                        {this.state.orgSettings.organizationId !== "e20ba3bd-c29a-4c0c-bd61-5f40e963d11f" && <div style={{ display: "flex", flexDirection: 'column', margin: '0 auto', width: '300px', alignItems: "left", justifyContent: "left", textAlign: "left" }}>
                        <Label htmlFor="DOB" marginBottom={4}>Birthday</Label>
                            <DatePicker
                                selected={this.state.selectedDate ? this.state.selectedDate : null}
                                onChange={(date) => this.handleChange(date, 'member.DOB')}
                                dateFormat="MM/dd" // Display only month and day
                                showMonthDropdown
                                showDayDropdown
                                dropdownMode="select"
                                placeholderText="Select month and day"
                                renderCustomHeader={({ date, changeMonth }) => (
                                    <div>
                                        <select
                                        value={new Date().getFullYear()}
                                        style={{ display: 'none' }} // Hide the year dropdown
                                        >
                                        <option value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
                                        </select>
                                        <select
                                        value={new Date(date).getMonth()}
                                        onChange={({ target: { value } }) => changeMonth(value)}
                                        >
                                        {Array.from({ length: 12 }, (_, i) => (
                                            <option key={i} value={i}>
                                            {new Date(0, i).toLocaleString('default', { month: 'long' })}
                                            </option>
                                        ))}
                                        </select>
                                    </div>
                                    )}
                            />
                        </div>}
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                            <Checkbox
                            // label={"I agree to the" + <a href="https://www.wishwellbyonegift.com/terms-of-service" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'black' }}>Terms of Service and Privacy Policy</a>}
                            label={
                            <span>I opt in to receive emails from Wishwell and agree to the&nbsp;
                            <a href="https://onegift.ai/the-legal-stuff" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                Terms
                            </a>
                            </span>
                            }
                            checked={this.state.legalStuff}
                            onChange={(e) => this.setState({ legalStuff: e.target.checked })}
                            width={300}
                            />
                        </div>
                    </Dialog>
                </Pane>
                </div>
                {/* Dialog to update an account */}
                <div style={{ display: "flex", flexDirection: 'column', width: '300px', alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                <Pane>
                    <Dialog
                        isShown={this.state.updateMemberModalShown}
                        // isShown={true}
                        shouldCloseOnOverlayClick={false}
                        shouldCloseOnEscapePress={false}
                        title="Update my WishWell Info"
                        isConfirmLoading={this.state.updateMemberLoading}
                        onConfirm={() => { this.setState({ updateMemberLoading: true }); this.updateMember(); } }
                        confirmLabel={'Update My Info'}
                        onCloseComplete={() => this.setState({ updateMemberModalShown: false, updateMemberLoading: false })}
                    >
                        <div style={{ display: "flex", flexDirection: 'column', margin: '0 auto', width: '300px', alignItems: "left", justifyContent: "left", textAlign: "left" }}>
                            <Label htmlFor="fname" marginBottom={4}>First Name*</Label>
                            <TextInputField
                                id="fname"
                                key="fname"
                                name="fname"
                                width={300}
                                onChange={(date) => this.handleChange(date, 'member.fname')}
                                value={this.state.member.fname}
                                type="text"
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: 'column', margin: '0 auto', width: '300px', alignItems: "left", justifyContent: "left", textAlign: "left" }}>
                            <Label htmlFor="lname" marginBottom={4}>Last Name</Label>
                            <TextInputField
                                id="lname"
                                key="lname"
                                name="lname"
                                width={300}
                                onChange={(date) => this.handleChange(date, 'member.lname')}
                                value={this.state.member.lname}
                                type="text"
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: 'column', margin: '0 auto', width: '300px', alignItems: "left", justifyContent: "left", textAlign: "left" }}>
                            <Label htmlFor="email" marginBottom={4}>Email*</Label>
                            <TextInputField
                                id="email"
                                key="email"
                                name="email"
                                width={300}
                                onChange={(date) => this.handleChange(date, 'member.email')}
                                value={this.state.member.email}
                                type="text"
                            />
                        </div>
                        {/* Don't show DOB for GLO */}
                        {this.state.orgSettings.organizationId !== "e20ba3bd-c29a-4c0c-bd61-5f40e963d11f" && <div style={{ display: "flex", flexDirection: 'column', margin: '0 auto', width: '300px', alignItems: "left", justifyContent: "left", textAlign: "left" }}>
                        <Label htmlFor="DOB" marginBottom={4}>Birthday</Label>
                            <DatePicker
                                selected={this.state.selectedDate ? this.state.selectedDate : null}
                                onChange={(date) => this.handleChange(date, 'member.DOB')}
                                dateFormat="MM/dd" // Display only month and day
                                showMonthDropdown
                                showDayDropdown
                                dropdownMode="select"
                                placeholderText="Select month and day"
                                renderCustomHeader={({ date, changeMonth }) => (
                                    <div>
                                        <select
                                        value={new Date().getFullYear()}
                                        style={{ display: 'none' }} // Hide the year dropdown
                                        >
                                        <option value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
                                        </select>
                                        <select
                                        value={new Date(date).getMonth()}
                                        onChange={({ target: { value } }) => changeMonth(value)}
                                        >
                                        {Array.from({ length: 12 }, (_, i) => (
                                            <option key={i} value={i}>
                                            {new Date(0, i).toLocaleString('default', { month: 'long' })}
                                            </option>
                                        ))}
                                        </select>
                                    </div>
                                    )}
                            />
                        </div>}
                        <div style={{ display: "flex", flexDirection: 'column', margin: '0 auto', width: '300px', alignItems: "left", justifyContent: "left", textAlign: "left" }}>
                            <Checkbox 
                                label="Send me reminders to make wishes"
                                id="eforms" 
                                name="eforms" 
                                checked={this.state.member.eforms} 
                                onChange={(checked) => this.handleChange(checked, 'member.eforms')}>
                            </Checkbox>
                        </div>
                            {/* Don't show DOB for GLO */}
                            {this.state.orgSettings.organizationId !== "e20ba3bd-c29a-4c0c-bd61-5f40e963d11f" &&                         <div style={{ display: "flex", flexDirection: 'column', margin: '0 auto', width: '300px', alignItems: "left", justifyContent: "left", textAlign: "left" }}>
                                <Checkbox 
                                    label="Send me a fundraising link before my birthday"
                                    id="birthdayBooster" 
                                    name="birthdayBooster" 
                                    checked={this.state.member.birthdayBooster} 
                                    onChange={(checked) => this.handleChange(checked, 'member.birthdayBooster')}>
                                </Checkbox>
                            </div>}
                            <div style={{ display: "flex", flexDirection: 'column', margin: '0 auto', width: '300px', alignItems: "left", justifyContent: "left", textAlign: "left" }}>
                            <Checkbox 
                                label="Send me a reminder on my friends' special day"
                                id="eReminders" 
                                name="eReminders" 
                                checked={this.state.member.eReminders} 
                                onChange={(checked) => this.handleChange(checked, 'member.eReminders')}>
                            </Checkbox>
                        </div>
                    </Dialog>
                </Pane>
                </div>
                {/* Dialog to collect email to reset password */}
                <div style={{ display: "flex", flexDirection: 'column', width: '300px', alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                <Pane>
                    <Dialog
                        isShown={this.state.updateMemberEmailConfirmationModalShown}
                        shouldCloseOnOverlayClick={false}
                        shouldCloseOnEscapePress={false}
                        title="Update Member Details"
                        isConfirmLoading={this.state.updateMemberEmailConfirmationModalLoading}
                        onConfirm={() => { this.setState({ updateMemberEmailConfirmationModalLoading: true }); this.updateMemberEmailSend(); } }
                        confirmLabel={'Request Update Link'}
                        onCloseComplete={() => this.setState({ updateMemberEmailConfirmationModalShown: false, updateMemberEmailConfirmationModalLoading: false })}
                    >
                        <div style={{ display: "flex", flexDirection: 'column', alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                            <p>Please enter your email address below and we'll send you a link to update your information.</p>
                            <TextInputField
                                id="updateMemberEmail"
                                key="updateMemberEmail"
                                name="updateMemberEmail"
                                label="Your Email"
                                width={300}
                                onChange={this.handleInputChange}
                                type="text"
                            />
                        </div>
                    </Dialog>
                </Pane>
            </div>
        </div>
      )
    }
    }
    navBar() {
        return (
            <nav className='navbar'>
                <div style={{ position: "fixed", top: 0, left: 0, right: 0, zIndex: 999 }}>
                    <div style={{ textAlign: "center", backgroundColor: this.state.orgSettings.brandColor, padding: "20px", height: "40px" }}>
                        {this.state.orgSettings.logo && this.state.orgSettings.logo[0] !== "" && <div><img src={this.state.orgSettings.logo && this.state.orgSettings.logo[0] !== "" ? this.state.orgSettings.logo[1] : wishWellLogo} alt={this.state.orgSettings.logo ? this.state.orgSettings.logo[0] : wishWellLogo} style={{ float: "left", height: "40px" }}></img></div>}
                        <div className='nav-links'>
                            <Button iconBefore={VideoIcon} onClick={() => window.open('http://wishwell.ai/wishestutorial', '_blank')}>Tutorial</Button>
                            {this.state.orgSettings.organizationId !== '' && <React.Fragment>
                                {this.state.organization.parentOrgId !== "859d0bb4-21c4-4c0d-9804-126093d78552" && <React.Fragment>
                                    &nbsp;<Button iconBefore={DollarIcon} onClick={() => this.setState({ donateNowModalShown: true })}>Donate Now</Button>
                                    &nbsp;<Button iconBefore={AddIcon} onClick={() => this.setState({ registerModalShown: true })}>Register</Button>
                                </React.Fragment>}
                                &nbsp;<Button iconBefore={EditIcon} onClick={() => this.setState({ updateMemberEmailConfirmationModalShown: true })}>Update My Info</Button>
                                &nbsp;<Tooltip content="Copy referral link to clipboard. We'll donate $100 (in your local currency) for every referral you generate!"><Button iconBefore={FollowerIcon} onClick={() => {
                                                        navigator.clipboard.writeText('https://www.wishwell.onegift.ai/?source=referral&referrer=' + this.state.orgSettings.organizationId)
                                                        toaster.success('Referral link copied to clipboard. Now share it with your friends via email and social!', {duration: 5,})
                                                    }}>Refer WishWell</Button></Tooltip>
                                </React.Fragment>}
                            &nbsp;<Button iconBefore={SettingsIcon} onClick={() => window.open('https://www.wishwell.onegift.ai/', '_blank')}>Admin Login</Button>
                            &nbsp;<Button iconBefore={HelpIcon} onClick={() => window.open('https://onegift.ai/resources/how-to-make-well-wishes-online', '_blank')}>Help</Button>
                        </div>
                        <div class="mobile">
                        <Popover
                        position={Position.BOTTOM_LEFT}
                        content={
                          <Menu>
                            <Menu.Group>
                                <Menu.Item icon={VideoIcon}>
                                    <Button appearance="minimal" onClick={() => window.open('http://wishwell.ai/wishestutorial')}>Tutorial</Button>
                                </Menu.Item>
                            {this.state.orgSettings.organizationId !== '' && <React.Fragment>
                                {this.state.organization.parentOrgId !== "859d0bb4-21c4-4c0d-9804-126093d78552" && <React.Fragment>
                                    <Menu.Item icon={DollarIcon}><Button appearance='minimal' onClick={() => this.setState({ donateNowModalShown: true })}>Donate Now</Button></Menu.Item>

                                    <Menu.Item icon={AddIcon}><Button appearance="minimal" onClick={() => this.setState({ registerModalShown: true })}>Register</Button></Menu.Item>
                                </React.Fragment>}

                                <Menu.Item icon={EditIcon}><Button appearance="minimal" onClick={() => this.setState({ updateMemberEmailConfirmationModalShown: true })}>Update My Info</Button></Menu.Item>
                                <Menu.Item icon={FollowerIcon}>
                                <Tooltip content="Copy referral link to clipboard. We'll donate $100 (in your local currency) for every referral you generate!"><Button appearance="minimal" onClick={() => {
                                                      navigator.clipboard.writeText('https://www.wishwell.onegift.ai/?source=referral&referrer=' + this.state.orgSettings.organizationId)
                                                      toaster.success('Referral link copied to clipboard. Now share it with your friends via email and social!', {duration: 5,})
                                                  }}>Referral Link</Button></Tooltip>
                              </Menu.Item>
                            </React.Fragment>}
                              <Menu.Item icon={SettingsIcon}>
                                &nbsp;<Button appearance="minimal" onClick={() => window.open('https://www.wishwell.onegift.ai/', '_blank')}>Admin Login</Button>
                              </Menu.Item>
                              <Menu.Item icon={HelpIcon}>
                                &nbsp;<Button appearance="minimal"  onClick={() => window.open('https://www.onegift.ai/resources', '_blank')}>Help</Button>
                              </Menu.Item>
                            </Menu.Group>
                          </Menu>
                        }
                      >
                        <IconButton icon={AlignJustifyIcon} marginRight={0} style={{ marginLeft: 'auto' }} />
                      </Popover>
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
}   

const OrderItem = (props) => {
    return(
        <div>
            <p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', verticalAlign: 'middle' }} onClick={() => {props.handleChange(props.id)}}><input type="checkbox" id="ordered" name="ordered" checked={props.checked} /> &nbsp;{props.name} - {props.birthday}</p>
        </div>
    )
}

export default MakeAWish