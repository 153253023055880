/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMember = /* GraphQL */ `
  mutation CreateMember(
    $input: CreateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    createMember(input: $input, condition: $condition) {
      id
      pnumber
      mnumber
      ptype
      pactive
      eforms
      pforms
      ecards
      pcards
      eReminders
      birthdayBooster
      fname
      lname
      email
      phone
      address1
      address2
      DOB
      lifeCycle
      anumber
      apayment
      notes
      searchField
      organizationId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMember = /* GraphQL */ `
  mutation UpdateMember(
    $input: UpdateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    updateMember(input: $input, condition: $condition) {
      id
      pnumber
      mnumber
      ptype
      pactive
      eforms
      pforms
      ecards
      pcards
      eReminders
      birthdayBooster
      fname
      lname
      email
      phone
      address1
      address2
      DOB
      lifeCycle
      anumber
      apayment
      notes
      searchField
      organizationId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMember = /* GraphQL */ `
  mutation DeleteMember(
    $input: DeleteMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    deleteMember(input: $input, condition: $condition) {
      id
      pnumber
      mnumber
      ptype
      pactive
      eforms
      pforms
      ecards
      pcards
      eReminders
      birthdayBooster
      fname
      lname
      email
      phone
      address1
      address2
      DOB
      lifeCycle
      anumber
      apayment
      notes
      searchField
      organizationId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      donorPNumber
      donorName
      receiverPNumber
      year
      paymentType
      checkNumber
      amount
      checkDate
      organizationId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      donorPNumber
      donorName
      receiverPNumber
      year
      paymentType
      checkNumber
      amount
      checkDate
      organizationId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      donorPNumber
      donorName
      receiverPNumber
      year
      paymentType
      checkNumber
      amount
      checkDate
      organizationId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSetting = /* GraphQL */ `
  mutation CreateSetting(
    $input: CreateSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    createSetting(input: $input, condition: $condition) {
      id
      chapterName
      orgEmail
      minWishPrice
      events
      customFields
      makeAWishURL
      defaultWishers
      envelopeReturnName
      envelopeReturnAddressLine1
      envelopeReturnAddressLine2
      logo
      brandColor
      taxNumber
      orgType
      giveLocal
      paymentType
      paymentId
      country
      currency
      bankInfo
      stripeAccountId
      stripeOnboardingComplete
      referrer
      referralCredits
      fullAuto
      birthdayBooster
      fullAutoEnabledDate
      charityPartner
      organizationId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSetting = /* GraphQL */ `
  mutation UpdateSetting(
    $input: UpdateSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    updateSetting(input: $input, condition: $condition) {
      id
      chapterName
      orgEmail
      minWishPrice
      events
      customFields
      makeAWishURL
      defaultWishers
      envelopeReturnName
      envelopeReturnAddressLine1
      envelopeReturnAddressLine2
      logo
      brandColor
      taxNumber
      orgType
      giveLocal
      paymentType
      paymentId
      country
      currency
      bankInfo
      stripeAccountId
      stripeOnboardingComplete
      referrer
      referralCredits
      fullAuto
      birthdayBooster
      fullAutoEnabledDate
      charityPartner
      organizationId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSetting = /* GraphQL */ `
  mutation DeleteSetting(
    $input: DeleteSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    deleteSetting(input: $input, condition: $condition) {
      id
      chapterName
      orgEmail
      minWishPrice
      events
      customFields
      makeAWishURL
      defaultWishers
      envelopeReturnName
      envelopeReturnAddressLine1
      envelopeReturnAddressLine2
      logo
      brandColor
      taxNumber
      orgType
      giveLocal
      paymentType
      paymentId
      country
      currency
      bankInfo
      stripeAccountId
      stripeOnboardingComplete
      referrer
      referralCredits
      fullAuto
      birthdayBooster
      fullAutoEnabledDate
      charityPartner
      organizationId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      userName
      NPS
      lifeCycle
      permissions
      organizationId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      userName
      NPS
      lifeCycle
      permissions
      organizationId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      userName
      NPS
      lifeCycle
      permissions
      organizationId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      name
      active
      parentOrgId
      independent
      source
      referrer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
      name
      active
      parentOrgId
      independent
      source
      referrer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization(
    $input: DeleteOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    deleteOrganization(input: $input, condition: $condition) {
      id
      name
      active
      parentOrgId
      independent
      source
      referrer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTransaction = /* GraphQL */ `
  mutation CreateTransaction(
    $input: CreateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    createTransaction(input: $input, condition: $condition) {
      id
      transactionDate
      quarter
      year
      wishType
      donorPNumber
      donorFName
      donorLName
      donorEmail
      donorAddress
      donorPhone
      receiverPNumbers
      localCurrency
      localAmount
      amount
      transactionNumber
      paymentType
      organizationId
      organizationName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTransaction = /* GraphQL */ `
  mutation UpdateTransaction(
    $input: UpdateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    updateTransaction(input: $input, condition: $condition) {
      id
      transactionDate
      quarter
      year
      wishType
      donorPNumber
      donorFName
      donorLName
      donorEmail
      donorAddress
      donorPhone
      receiverPNumbers
      localCurrency
      localAmount
      amount
      transactionNumber
      paymentType
      organizationId
      organizationName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTransaction = /* GraphQL */ `
  mutation DeleteTransaction(
    $input: DeleteTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    deleteTransaction(input: $input, condition: $condition) {
      id
      transactionDate
      quarter
      year
      wishType
      donorPNumber
      donorFName
      donorLName
      donorEmail
      donorAddress
      donorPhone
      receiverPNumbers
      localCurrency
      localAmount
      amount
      transactionNumber
      paymentType
      organizationId
      organizationName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createReport = /* GraphQL */ `
  mutation CreateReport(
    $input: CreateReportInput!
    $condition: ModelReportConditionInput
  ) {
    createReport(input: $input, condition: $condition) {
      id
      organizations
      enabledOrganizations
      activeOrganizations
      orgChurn
      users
      verifiedUsers
      members
      activeMembers
      memberChurn
      wishes
      donations
      feesGenerated
      customerSources {
        source
        count
        __typename
      }
      donorSources {
        source
        count
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateReport = /* GraphQL */ `
  mutation UpdateReport(
    $input: UpdateReportInput!
    $condition: ModelReportConditionInput
  ) {
    updateReport(input: $input, condition: $condition) {
      id
      organizations
      enabledOrganizations
      activeOrganizations
      orgChurn
      users
      verifiedUsers
      members
      activeMembers
      memberChurn
      wishes
      donations
      feesGenerated
      customerSources {
        source
        count
        __typename
      }
      donorSources {
        source
        count
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteReport = /* GraphQL */ `
  mutation DeleteReport(
    $input: DeleteReportInput!
    $condition: ModelReportConditionInput
  ) {
    deleteReport(input: $input, condition: $condition) {
      id
      organizations
      enabledOrganizations
      activeOrganizations
      orgChurn
      users
      verifiedUsers
      members
      activeMembers
      memberChurn
      wishes
      donations
      feesGenerated
      customerSources {
        source
        count
        __typename
      }
      donorSources {
        source
        count
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
