import React, { Component } from 'react';
import { withCookies } from 'react-cookie';

// UI
import { Button } from 'evergreen-ui'

class Notification extends Component {

    constructor(props) {
        super(props);
      
        const { cookies, announcements } = props;
        const dismissedAnnouncements = cookies.get('dismissedAnnouncements') || [];
      
        const visibleAnnouncements = announcements.filter(
          (announcement) => !dismissedAnnouncements.includes(announcement.id)
        );
      
        this.state = {
          visibleAnnouncements,
        };
    }

    handleDismissFeatureCookie(announcementId) {
        const { cookies, announcements } = this.props;
        const dismissedAnnouncements = cookies.get('dismissedAnnouncements') || [];
    
        dismissedAnnouncements.push(announcementId);
        cookies.set('dismissedAnnouncements', dismissedAnnouncements, { path: '/' });
    
        const visibleAnnouncements = announcements.filter(
        (announcement) => !dismissedAnnouncements.includes(announcement.id)
        );
    
        this.setState({ visibleAnnouncements });
    }

    moreInfo(url) {
        window.open(url, "_blank");
    }

    render() {
        const { visibleAnnouncements } = this.state;
      
        return (
          <>
            {visibleAnnouncements.map((announcement) => (
              <div key={announcement.id} className="notification" style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                {announcement.message}
                &nbsp;<Button onClick={() => this.moreInfo(announcement.url)}>More Info</Button>
                &nbsp;<Button onClick={() => this.handleDismissFeatureCookie(announcement.id)}>Dismiss</Button>
              </div>
            ))}
          </>
        );
    }
}
export default withCookies(Notification);