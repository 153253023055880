/* src/App.js */
import React, { Component } from 'react'

// Amplify libraries
import { API, graphqlOperation, Auth } from 'aws-amplify'
import awsconfig from './aws-exports';
import { listSettings, settingsByOrganizationId, userByUserName } from './graphql/queries'

// Extensions
import Main from './Components/Main'

// Bug tracking
import Bugsnag from '@bugsnag/js'

// Analytics
import mixpanel from 'mixpanel-browser';

Auth.configure(awsconfig);

export class AppEntry extends Component {

  constructor(props) {
    super(props)
    this.state = { 
      brandColor: "#0A003A"
    }
    this.componentDidMount = this.componentDidMount.bind(this);
  }

  componentDidMount() {
    this.loadPage()
  }

  async loadPage() {
    try {
      var brandColor = "#0A003A"
      if (this.props.authState === 'signedIn') {
        // Check for existing setting record
        const user_info = await Auth.currentUserInfo()
        const username = user_info.username.toLowerCase()
        const user = await API.graphql(graphqlOperation(userByUserName, { userName: username }))
        const orgId = user.data.userByUserName.items[0].organizationId
        const settings_record = await API.graphql(graphqlOperation(settingsByOrganizationId, { organizationId: orgId }))
        if (settings_record.data.settingsByOrganizationId.items[0] && settings_record.data.settingsByOrganizationId.items[0].brandColor) {
          brandColor = settings_record.data.settingsByOrganizationId.items[0].brandColor
        }
      }
      this.setState({
          brandColor: brandColor,
      })
    } catch (error) {
      console.log(error)
      Bugsnag.notify(error)
    }
  }

  render() {
    if (this.props.authState === 'signedIn') {
      mixpanel.track('WishWell app user session started');
      return (
        <div>
          <div style={{ textAlign: "center" }}>
              <Main />
          </div>
        </div>
      )
    }
    else {
      return null
    }
  }
}