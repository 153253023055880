import React, { useState } from 'react'
import {Authenticator} from 'aws-amplify-react'
//import './App.css'
import Amplify from 'aws-amplify'
import awsconfig from './aws-exports'
import AuthWrapper from './AuthWrapper'

// Analytics
import mixpanel from 'mixpanel-browser';

Amplify.configure(awsconfig)

// Get backend environment
const envStart = awsconfig.aws_cloud_logic_custom[0].endpoint.lastIndexOf('/');
const env = awsconfig.aws_cloud_logic_custom[0].endpoint.slice(envStart+1);

env === 'dev' ? 
  mixpanel.init('4ae47a1aaceb8492e67a539b65e47a96', {debug: true}) :
  mixpanel.init('3f21124f040a7330a329c01149dd5fba', {debug: false})

function App() {
  const [authState, setAuthState] = useState(null);

  const handleStateChange = (newState, data) => {
    setAuthState(newState);
  };
  return (
    <div>
      <header>
        <Authenticator hideDefault={true} amplifyConfig={awsconfig}>
          <AuthWrapper onStateChange={handleStateChange} />
        </Authenticator>
      </header>
    </div>
  )
}

export default App