/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "publicorderform",
            "endpoint": "https://yj0xdum3dc.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        },
        {
            "name": "wishwellapi",
            "endpoint": "https://3ur77j5kvf.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        },
        {
            "name": "wishwellpayments",
            "endpoint": "https://p85tri4pb9.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://psb4r34gvrfebj7rormw6tiisq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:ca0721be-17e4-46cc-8b17-5bdbfc7e9ac5",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_DI6H4BoHQ",
    "aws_user_pools_web_client_id": "3sv9f30aua8jfb9jhujs8nj3ar",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "wishwell-etl-data134950-master",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
